import React from 'react';

export default function HomeIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.60806 1.14357C9.8391 0.952143 10.1609 0.952143 10.392 1.14357L18.7491 8.06666C19.0305 8.29976 19.0831 8.73446 18.8667 9.03749C18.6502 9.34058 18.2466 9.39728 17.9652 9.16411L17.7143 8.95628V16.9231C17.7143 18.0702 16.8509 19 15.7857 19H4.21432C3.14919 19 2.28575 18.0702 2.28575 16.9231V8.95628L2.03484 9.16411C1.75343 9.39728 1.34982 9.34058 1.13334 9.03749C0.916875 8.73446 0.969518 8.29976 1.25093 8.06666L9.60806 1.14357ZM8.43035 6.99795C8.5217 6.72241 8.76406 6.53846 9.03573 6.53846H11.2857C11.4837 6.53846 11.6706 6.6367 11.7924 6.80472C11.9142 6.97275 11.9574 7.19186 11.9094 7.39866L11.4662 9.30769H12.8929C13.1235 9.30769 13.3364 9.44069 13.4509 9.65627C13.5654 9.87186 13.5639 10.1369 13.4469 10.351L10.2327 16.2356C10.0802 16.5149 9.76813 16.6429 9.48174 16.5437C9.19535 16.4445 9.01111 16.1446 9.03836 15.822L9.29608 12.7692H7.42859C7.21953 12.7692 7.02353 12.6598 6.90312 12.4757C6.78271 12.2917 6.75289 12.0561 6.82321 11.8441L8.43035 6.99795Z"
				fill="white"
				fillOpacity="0.3"
			/>
		</svg>
	);
}
