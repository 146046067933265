import React from 'react';

export default function TelegramIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
		>
			<path
				d="M27 3.85046L23.204 26.0011C23.204 26.0011 22.6734 27.537 21.2132 26.7997L12.4143 19.0051C13.5969 17.7747 22.7718 8.22765 23.1731 7.7947C23.7933 7.12467 23.4084 6.72591 22.6875 7.23263L9.13532 17.1936L3.90684 15.1564C3.90684 15.1564 3.0837 14.8184 3.00448 14.0811C2.9248 13.3438 3.93355 12.9445 3.93355 12.9445L25.2483 3.2667C25.2483 3.2667 27 2.37585 27 3.85046Z"
				fill="white"
			/>
		</svg>
	);
}
