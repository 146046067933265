import styled from 'styled-components';
import ButtonUI from '../UI/Button/ButtonUI';
import SwapIc from '../Icons/SwapIc';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';

const SwapBanner = () => {
	const navigate = useNavigate();
	return (
		<Wrapper>
			<Banner src="/images/landing/swap.png" />
			<ContentWrapper>
				<Content>
					<Text>Swap With PadTON</Text>
					<SwapBtn onClick={() => navigate(ROUTES.SWAP)}>
						<SwapIc /> Swap Now
					</SwapBtn>
				</Content>
			</ContentWrapper>
		</Wrapper>
	);
};

export default SwapBanner;

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	padding: 0 16px;
	box-sizing: border-box;
	height: fit-content;
`;

const Banner = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
	min-height: 100px;
`;

const ContentWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 40px;
	gap: 8px;
`;
const Text = styled.div`
	color: #fff;
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 600;
`;

const SwapBtn = styled(ButtonUI)`
	height: 34px;
`;
