import styled from 'styled-components';
import SectionTitle from '../UI/SectionTitle';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes';
import IDOCard from '../IDO/Card';
import { QuerySearchStatus } from '../../constants/launchpad';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import fairlaunchRequest from '../../service/fairlaunch.request';
import { getStaleTime } from '../../utils/common';
import { normalizeDataForProjectCard } from '../../utils/launchpad';
import Notfound from '../UI/NotFound';
const DEFAULT_PARAMS = {
	page: 1,
	pageSize: 3,
	searchStatus: QuerySearchStatus.ALL,
	order: 'updatedAt desc',
};
const IDOList = () => {
	const queryClient = useQueryClient();
	const [launchpads, setLaunchpads] = useState<IDOItem[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getPools(DEFAULT_PARAMS);
	}, []);
	const getPools = async (params: PoolContentParams) => {
		try {
			setLoading(true);
			const res = await queryClient.fetchQuery({
				queryKey: [QUERY_KEYS.GET_POOLS, params],
				queryFn: () => fairlaunchRequest.getPoolContent(params),
				staleTime: getStaleTime(15),
			});
			const formatedData = res.data.data.map((item: PoolContent) =>
				normalizeDataForProjectCard(item)
			);

			setLaunchpads(formatedData);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};
	return (
		<Wrapper>
			<Header>
				<StyledSectionTitle text="IDO List" />
				<ViewAll to={ROUTES.IDO_LIST}>View All</ViewAll>
			</Header>
			<Cards>
				{loading ? (
					<LoadingWrapper style={{ height: '389px' }}>
						<img
							src="/images/loading.svg"
							alt="loading"
							width={'30px'}
							height={'30px'}
						/>
					</LoadingWrapper>
				) : (
					<>
						{launchpads.length === 0 ? (
							<Notfound content="No project found" />
						) : (
							<>
								{launchpads.map((launchpad: IDOItem) => (
									<StyledIDOCard key={launchpad.address} {...launchpad} />
								))}
							</>
						)}
					</>
				)}
			</Cards>
		</Wrapper>
	);
};

export default IDOList;

const LoadingWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
`;

const StyledIDOCard = styled(IDOCard)`
	min-width: 85%;
	height: 389px;
`;
const StyledSectionTitle = styled(SectionTitle)`
	margin-bottom: 0;
`;
const Wrapper = styled.div`
	width: 100%;
`;
const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-right: 16px;
	box-sizing: border-box;
`;

const ViewAll = styled(Link)`
	color: #d5d5d5;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 21px;
	cursor: pointer;
	text-decoration: none;
`;

const Cards = styled.div`
	overflow-x: auto;
	display: flex;
	gap: 12px;
	overflow-y: hidden;
	height: 100%;
	margin-top: 12px;
	padding-left: 16px;
	> div:last-child {
		margin-right: 16px;
	}
`;
