import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import FriendActiveIc from '../../Icons/FriendActiveIc';
import FriendIc from '../../Icons/FriendIc';
import HomeActiveIc from '../../Icons/HomeActiveIc';
import HomeIc from '../../Icons/HomeIc';
import MissionActiveIc from '../../Icons/MissionActiveIc';
import MissionIc from '../../Icons/MissionIc';
import RankingActiveIc from '../../Icons/RankingActiveIc';
import RankingIc from '../../Icons/RankingIc';
import WalletActiveIc from '../../Icons/WalletActiveIc';
import WalletIc from '../../Icons/WalletIc';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';

interface Tab {
	key: number;
	bg: string;
	name: string;
	route: string;
	icon: JSX.Element;
	activeIcon: JSX.Element;
}

export default function Tabbar() {
	const location = useLocation();
	const tabs = [
		{
			key: 1,
			bg: '/images/home/tab-bg-3.png',
			name: 'Home',
			route: ROUTES.HOME,
			icon: <HomeIc />,
			activeIcon: <HomeActiveIc />,
		},

		{
			key: 2,
			bg: '/images/home/tab-bg-5.png',
			name: 'Earns',
			route: ROUTES.EARN,
			icon: <WalletIc />,
			activeIcon: <WalletActiveIc />,
		},
		{
			key: 3,
			bg: '/images/home/tab-bg-1.png',
			name: 'Missions',
			route: ROUTES.MISSION,
			icon: <MissionIc />,
			activeIcon: <MissionActiveIc />,
		},
		{
			key: 4,
			name: 'Ranking',
			route: ROUTES.RANKING,
			icon: <RankingIc />,
			activeIcon: <RankingActiveIc />,
		},
		{
			key: 5,
			bg: '/images/home/tab-bg-2.png',
			name: 'Friends',
			route: ROUTES.REFERRAL,
			icon: <FriendIc />,
			activeIcon: <FriendActiveIc />,
		},
	] as Tab[];
	const [tabKey, setTabKey] = useState<number>(1);
	const navigate = useNavigate();

	const onSelectTab = (tab: Tab) => {
		setTabKey(tab.key);
		navigate(tab.route);
	};

	useEffect(() => {
		setTabKey(tabs.find((tab) => tab.route === location.pathname)?.key || 3);
	}, [location.pathname]);

	return (
		<Wrapper>
			{tabs.map((tabItem) => {
				const active = tabItem.key === tabKey;
				return (
					<TabItemWrapper
						key={tabItem.key}
						active={active}
						onClick={() => {
							onSelectTab(tabItem);
						}}
					>
						<Icon active={active}>
							{active ? tabItem.activeIcon : tabItem.icon}
						</Icon>
						{active && <TabText>{tabItem.name}</TabText>}
					</TabItemWrapper>
				);
			})}
		</Wrapper>
	);
}

const Wrapper = styled.div`
	width: 100%;
	box-sizing: border-box;
	position: fixed;
	bottom: 0;
	z-index: 2;
	padding: 16px 16px 16px 16px;
	gap: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px -2px 4px 0px rgba(255, 255, 255, 0.1);
	background: #061228;
	height: 92px;
`;

const TabItemWrapper = styled.div<{ active: boolean }>`
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 100px;
	gap: 8px;
	padding: ${(props) => (!props.active ? '10px' : '0px 20px 0 0')};
	transition: all 0.6s ease;
	background-color: ${(props) => (props.active ? '#3998ff' : '#00000000')};
	will-change: auto;
`;

const Icon = styled.div<{ active: boolean }>`
	padding: ${(props) => (props.active ? '10px' : '0px')};
	border-radius: 100px;
	background: ${(props) => (props.active ? '#007aff' : '#00000000')};
	transition: all 0.6s ease;
	will-change: auto;
`;

const TabText = styled(Typography)`
	font-size: 16px;
	font-weight: 500;
	line-height: 100%; /* 16px */
	transition: all 0.6s ease;
	will-change: auto;
`;
