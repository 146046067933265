import React, { useEffect } from 'react';
import { setOkxTonConnect } from '../../redux/reducers/wallet.slice';
import { useAppDispatch } from '../../redux/store';
import { OKXTonConnect } from '@okxconnect/tonsdk';

const ProviderOKXWallet = () => {
	const dispatch = useAppDispatch();

	const connectTonOKXWallet = () => {
		const okxTonConnect = new OKXTonConnect({
			metaData: {
				name: process.env.REACT_MINIAPP_NAME || 'PadTON',
				icon: process.env.REACT_APP_ICON_URL || '',
			},
		});
		okxTonConnect.restoreConnection();
		dispatch(setOkxTonConnect(okxTonConnect));
	};

	useEffect(() => {
		connectTonOKXWallet();
	}, []);

	return <></>;
};

export default ProviderOKXWallet;
