import moment from 'moment';

export const formatLotteryResult = (resultNumber: number): string => {
	if (resultNumber < 10) {
		return `000${resultNumber}`;
	}
	if (resultNumber < 100) {
		return `00${resultNumber}`;
	}
	if (resultNumber < 1000) {
		return `0${resultNumber}`;
	}
	return resultNumber.toString();
};

export const formatStartDate = (date: string) => {
	return moment(date).utc().format('ddd, DD MMM, YYYY');
};
