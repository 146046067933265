import React from 'react';
import Balance from './Balance';
import Campaign from './Campaign';
import GameHot from './GameHot';
import LatestNews from './LatestNews';
import styled from 'styled-components';
import YourRewards from './YourRewards';
import { fadeIn } from '../../constants/css';
import IDOList from './IDOList';
import SwapBanner from './SwapBanner';

const Landing = () => {
	return (
		<Wrapper>
			<Balance />
			<SwapBanner />
			<Campaign />
			<GameHot />
			<IDOList />
			<LatestNews />
			<YourRewards />
		</Wrapper>
	);
};

const Wrapper = styled.div`
	max-width: 100vw;
	display: flex;
	flex-direction: column;
	gap: 12px;
	animation: ${fadeIn} 0.5s ease;
`;

export default Landing;
