const SwapIc = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={20}
			height={21}
			viewBox="0 0 20 21"
			fill="none"
		>
			<g clipPath="url(#clip0_2763_564)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M3.99963 17C7.31121 20.3116 12.6881 20.3116 15.9996 17C19.3112 13.6884 19.3112 8.31157 15.9996 5C12.6881 1.68843 7.31121 1.68843 3.99963 5C0.688062 8.31157 0.688062 13.6884 3.99963 17ZM4.85678 16.1429C2.01835 13.3044 2.01835 8.69557 4.85678 5.85714C7.69521 3.01871 12.3041 3.01871 15.1425 5.85714C17.9809 8.69557 17.9809 13.3044 15.1425 16.1429C12.3041 18.9813 7.69521 18.9813 4.85678 16.1429Z"
					fill="white"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M7.21989 9.17084L9.20393 7.1868C9.44034 6.95038 9.44034 6.56606 9.20393 6.32965C8.96752 6.09324 8.5832 6.09324 8.34679 6.32965C8.34679 6.32965 6.40882 8.26761 5.63836 9.03807C5.41286 9.26357 5.34558 9.60244 5.46742 9.89705C5.58926 10.191 5.87659 10.3832 6.19544 10.3832H14.2431C14.5777 10.3832 14.8493 10.1116 14.8493 9.77702C14.8493 9.44241 14.5777 9.17084 14.2431 9.17084H7.21989Z"
					fill="white"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.0987 12.8348L11.3603 14.8516C11.1414 15.105 11.1701 15.4881 11.4235 15.7063C11.6769 15.9251 12.06 15.8966 12.2784 15.6433L14.6214 12.9251C14.8226 12.6918 14.8691 12.362 14.7411 12.0819C14.6125 11.8019 14.333 11.6224 14.0247 11.6224H5.75773C5.42331 11.6224 5.15159 11.894 5.15159 12.2286C5.15159 12.5632 5.42331 12.8348 5.75773 12.8348H13.0987Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2763_564">
					<rect
						width={20}
						height={20}
						fill="white"
						transform="translate(0 0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default SwapIc;
