const PersonIc = () => {
	return (
		<svg
			width={17}
			height={17}
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.22491 3.05078C8.22491 2.70466 8.32754 2.36632 8.51983 2.07853C8.71213 1.79075 8.98544 1.56645 9.30521 1.43399C9.62498 1.30154 9.97685 1.26688 10.3163 1.33441C10.6558 1.40193 10.9676 1.5686 11.2123 1.81335C11.4571 2.05809 11.6238 2.36991 11.6913 2.70937C11.7588 3.04884 11.7241 3.40071 11.5917 3.72048C11.4592 4.04025 11.2349 4.31356 10.9472 4.50585C10.6594 4.69815 10.321 4.80078 9.97491 4.80078C9.51078 4.80078 9.06566 4.61641 8.73747 4.28822C8.40928 3.96003 8.22491 3.51491 8.22491 3.05078ZM15.4387 9.59953L12.6105 6.39328C12.4463 6.20707 12.2443 6.05793 12.018 5.95577C11.7918 5.85362 11.5463 5.80078 11.298 5.80078H8.65178C8.4035 5.80078 8.15806 5.85362 7.93176 5.95577C7.70547 6.05793 7.5035 6.20707 7.33928 6.39328L4.51116 9.59953C4.32752 9.78743 4.2252 10.0401 4.22633 10.3028C4.22746 10.5655 4.33194 10.8172 4.51718 11.0036C4.70243 11.1899 4.95356 11.2958 5.21628 11.2984C5.479 11.3011 5.73221 11.2002 5.92116 11.0177L7.53741 9.71953L6.30616 14.3995C6.19904 14.64 6.19106 14.913 6.28397 15.1593C6.37687 15.4057 6.56316 15.6054 6.80241 15.7152C7.04166 15.8251 7.31457 15.8361 7.56193 15.746C7.80928 15.6559 8.01112 15.4719 8.12366 15.2339L9.97491 12.0508L11.8237 15.2383C11.9362 15.4763 12.138 15.6603 12.3854 15.7504C12.6327 15.8405 12.9056 15.8295 13.1449 15.7196C13.3841 15.6098 13.5704 15.41 13.6633 15.1637C13.7562 14.9174 13.7483 14.6444 13.6412 14.4039L12.4124 9.71953L14.0312 11.0177C14.2201 11.2002 14.4733 11.3011 14.736 11.2984C14.9988 11.2958 15.2499 11.1899 15.4351 11.0036C15.6204 10.8172 15.7249 10.5655 15.726 10.3028C15.7271 10.0401 15.6248 9.78743 15.4412 9.59953H15.4387Z"
				fill="white"
				fillOpacity="0.5"
			/>
			<path
				d="M4.90606 3.2168C4.90606 2.87068 5.00869 2.53234 5.20099 2.24455C5.39328 1.95676 5.66659 1.73246 5.98636 1.60001C6.30613 1.46756 6.658 1.4329 6.99747 1.50042C7.33693 1.56795 7.64875 1.73462 7.8935 1.97936C8.13824 2.2241 8.30491 2.53592 8.37243 2.87539C8.43996 3.21486 8.4053 3.56672 8.27285 3.88649C8.14039 4.20626 7.91609 4.47958 7.62831 4.67187C7.34052 4.86416 7.00218 4.9668 6.65606 4.9668C6.19193 4.9668 5.74681 4.78242 5.41862 4.45423C5.09043 4.12605 4.90606 3.68093 4.90606 3.2168ZM12.1198 9.76555L9.29168 6.5593C9.12746 6.37308 8.9255 6.22394 8.6992 6.12179C8.47291 6.01963 8.22747 5.9668 7.97918 5.9668H5.33293C5.08465 5.9668 4.83921 6.01963 4.61292 6.12179C4.38662 6.22394 4.18466 6.37308 4.02043 6.5593L1.19231 9.76555C1.00867 9.95345 0.906352 10.2061 0.90748 10.4688C0.908608 10.7315 1.01309 10.9833 1.19834 11.1696C1.38358 11.3559 1.63471 11.4618 1.89743 11.4645C2.16015 11.4671 2.41336 11.3662 2.60231 11.1837L4.21856 9.88555L2.98731 14.5655C2.88019 14.806 2.87222 15.079 2.96512 15.3254C3.05803 15.5717 3.24432 15.7714 3.48356 15.8813C3.72281 15.9911 3.99572 16.0022 4.24308 15.912C4.49043 15.8219 4.69227 15.6379 4.80481 15.3999L6.65606 12.2168L8.50481 15.4043C8.61734 15.6423 8.81919 15.8263 9.06654 15.9164C9.31389 16.0065 9.5868 15.9955 9.82605 15.8856C10.0653 15.7758 10.2516 15.5761 10.3445 15.3297C10.4374 15.0834 10.4294 14.8104 10.3223 14.5699L9.09356 9.88555L10.7123 11.1837C10.9013 11.3662 11.1545 11.4671 11.4172 11.4645C11.6799 11.4618 11.931 11.3559 12.1163 11.1696C12.3015 10.9833 12.406 10.7315 12.4071 10.4688C12.4083 10.2061 12.3059 9.95345 12.1223 9.76555H12.1198Z"
				fill="white"
			/>
		</svg>
	);
};

export default PersonIc;
