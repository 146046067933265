import { toast } from 'react-toastify';

export const onCopy = async (text: string) => {
	if (navigator.clipboard) {
		try {
			await navigator.clipboard.writeText(text);
			toast.success('Copied!');
		} catch (error) {
			console.error(error);
			fallbackCopyText(text);
		}
	} else {
		fallbackCopyText(text);
	}
};

const fallbackCopyText = (text: string) => {
	const textArea = document.createElement('textarea');
	textArea.value = text;
	// Avoid scrolling to bottom
	textArea.style.position = 'fixed';
	textArea.style.opacity = '0'; // Hide it

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		const successful = document.execCommand('copy');
		toast.success('Copied!');
		console.log('successful', successful);
	} catch (err) {
		console.error(err);
	}

	document.body.removeChild(textArea);
};

export const formatCurrency = (number: number, decimal?: number): string => {
	return new Intl.NumberFormat('en-US', {
		style: 'decimal',
		maximumFractionDigits: decimal || 6,
	}).format(number);
};
