import React from 'react';
import styled from 'styled-components';
import GiftIc from '../Icons/GiftIc';
import TrophyIc from '../Icons/TrophyIc';
import { Flex, Skeleton } from 'antd';
import ClockIc from '../Icons/ClockIc';
import GroupUserIc from '../Icons/GroupUserIc';
import { StatusTag } from '../Campaign/Campaign';
import Countdown from 'react-countdown';
import { shortenNumber } from '../../utils/common';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { CAMPAIGN_TYPE } from '../../constants';

// const rendererTime = (startAt: string, endAt: string) => {
// 	if (new Date(endAt).valueOf() < new Date().valueOf()) {
// 		return 'End of campaign';
// 	}
// 	const start = new Date(startAt);
// 	//return follow format End in: 24 Days, 18:23:17
// 	const end = new Date(endAt);
// 	const diff = end.getTime() - start.getTime();
// 	const days = Math.floor(diff / (1000 * 60 * 60 * 24));
// 	const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
// 	const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
// 	const seconds = Math.floor((diff % (1000 * 60)) / 1000);
// 	return `${days} Days, ${hours}:${minutes}:${seconds}`;
// };

const CampaignCard = ({
	campaign,
	loading,
}: {
	campaign?: Campaign;
	loading?: boolean;
}) => {
	const navigate = useNavigate();
	const getDate = (campaign: Campaign) => {
		if (new Date(campaign.startAt).valueOf() > new Date().valueOf()) {
			return new Date(campaign.startAt).valueOf();
		}
		return new Date(campaign.endAt).valueOf();
	};
	const renderer = ({ days, hours, minutes, seconds }: any) => {
		return (
			<span>
				End in: {days} days, {hours.toString().padStart(2, 0)}:
				{minutes.toString().padStart(2, 0)}:{seconds.toString().padStart(2, 0)}
			</span>
		);
	};

	if (loading)
		return (
			<CardLoading>
				<Skeleton.Input
					style={{
						height: '220px',
						width: '100%',
						minWidth: '100%',
						backgroundColor: 'rgba(255, 255, 255, 0.1)',
					}}
				/>
				<CardLoadingContent>
					<Skeleton.Input
						style={{
							height: '16px',
							minWidth: '200px',
							backgroundColor: 'rgba(255, 255, 255, 0.1)',
						}}
					/>
					<Skeleton.Input
						style={{
							height: '16px',
							minWidth: '100%',
							backgroundColor: 'rgba(255, 255, 255, 0.1)',
						}}
					/>
				</CardLoadingContent>
			</CardLoading>
		);
	if (!campaign) return <></>;
	return (
		<CardWrapper
			onClick={() => navigate(ROUTES.CAMPAIGN + `?id=${campaign.id}`)}
		>
			<Card>
				<CardBg src={campaign.image} alt="campaign" />
				<StatusTag
					status={
						new Date(campaign.endAt).valueOf() < new Date().valueOf()
							? 'finished'
							: 'live'
					}
				/>
				<CardContentWrapper>
					<CardContent>
						<CardContentTitle>{campaign.name}</CardContentTitle>

						<Flex justify="space-between">
							{campaign.type !== CAMPAIGN_TYPE.MISSION && (
								<CardContentRangeDate>
									<ClockIc />
									<span>
										{/* End in: {rendererTime(campaign.startAt, campaign.endAt)} */}
										{new Date(campaign.endAt).valueOf() <
										new Date().valueOf() ? (
											'End of campaign'
										) : (
											<Countdown date={getDate(campaign)} renderer={renderer} />
										)}
									</span>
								</CardContentRangeDate>
							)}

							<CardContentTotalPerson
								style={{
									justifyContent:
										campaign.type === CAMPAIGN_TYPE.MISSION
											? 'flex-start'
											: 'flex-end',
									textAlign:
										campaign.type === CAMPAIGN_TYPE.MISSION ? 'left' : 'right',
								}}
							>
								<GroupUserIc />
								<span>{shortenNumber(campaign.participants)}</span>{' '}
								{campaign.participants > 1 ? 'Participants' : 'Participant'}
							</CardContentTotalPerson>
						</Flex>
					</CardContent>
				</CardContentWrapper>
			</Card>

			<Content>
				<Info>
					<InfoItem>
						<GiftIc />
						<InfoItemText>{campaign.prizeInfo}</InfoItemText>
					</InfoItem>
					<InfoItem>
						<TrophyIc />
						<InfoItemText>
							Complete all the missions below to grab the prize
						</InfoItemText>
					</InfoItem>
				</Info>
			</Content>
		</CardWrapper>
	);
};

const CardLoading = styled.div`
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	height: 296px;
	background: var(
		--Button-Linear,
		linear-gradient(90deg, #082954 0%, #143b6e 100%)
	);
	.ant-skeleton.ant-skeleton-element {
		width: 100% !important;
	}
`;

const CardLoadingContent = styled.div`
	padding: 0 8px 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: calc(296px - 220px);
	gap: 8px;
`;

const CardWrapper = styled.div`
	border-radius: 10px;
	overflow: hidden;
	background: var(
		--Button-Linear,
		linear-gradient(90deg, #082954 0%, #143b6e 100%)
	);
`;

const Card = styled.div`
	position: relative;
	width: 100%;
	height: 220px;
`;

const CardBg = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	flex-shrink: 0;
`;
const CardContentWrapper = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 100px;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding-bottom: 10px;
	background: linear-gradient(180deg, rgba(8, 41, 84, 0) 0%, #082954 100%);
`;

const CardContent = styled.div`
	padding: 0 16px;
`;
const CardContentTitle = styled.div`
	color: var(--Web-White, var(--Native-text_color, #fff));
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 26px; /* 162.5% */
	text-transform: uppercase;
`;

const CardContentRangeDate = styled.div`
	display: flex;
	justify-content: center;
	gap: 2px;

	color: var(--Web-White, var(--Native-text_color, #fff));
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px; /* 216.667% */
	/* text-transform: uppercase; */
`;

const CardContentTotalPerson = styled.div`
	display: flex;
	justify-content: center;
	gap: 2px;

	color: var(--Web-White, var(--Native-text_color, #fff));
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
	span {
		font-weight: 600;
		text-transform: uppercase;
	}
`;

const Content = styled.div`
	padding: 0 16px;
`;

const Info = styled.div`
	padding: 12px 0;
	display: flex;
	flex-direction: column;

	color: var(--Web-White, var(--Native-text_color, #fff));
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px; /* 216.667% */

	/* border-bottom: 1px solid #414141; */
`;

const InfoItem = styled.div`
	display: flex;
	gap: 6px;
	align-items: center;
`;

const InfoItemText = styled.div`
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export default CampaignCard;
