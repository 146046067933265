import { Form } from 'antd';
import PopupUI from '../UI/Popup/PopupUI';
import {
	Actions,
	ConfirmBtn,
	Divider,
	FilterLabel,
	FilterOption,
	ResetBtn,
	SortOptions,
	StyledInput,
} from './IDOList.styled';
import SearchIc from '../Icons/SearchIc';
import {
	QuerySearchStatus,
	SORT_OPTIONS,
	STATUS_OPTIONS,
} from '../../constants/launchpad';
import { useEffect } from 'react';

interface FilterPopupProps {
	open: boolean;
	params: PoolContentParams;
	setOpen: (open: boolean) => void;
	setParams: (params: PoolContentParams) => void;
}
const FilterPopup: React.FC<FilterPopupProps> = ({
	open,
	params,
	setOpen,
	setParams,
}: FilterPopupProps) => {
	const [form] = Form.useForm();
	const searchStatus = Form.useWatch('searchStatus', form);
	const sort = Form.useWatch('sort', form);

	useEffect(() => {
		form.setFieldsValue({
			search: params.search,
			searchStatus: params.searchStatus,
			sort: !!params.order
				? params.order === 'liquidityFeePercent desc'
					? 'lp-percent'
					: params.order === 'startTime desc'
					? 'start-time'
					: params.order === 'endTime asc'
					? 'end-time'
					: 'no-sort'
				: 'no-sort',
		});
	}, [JSON.stringify(params)]);

	const onFinish = (values: any) => {
		const newParams = { ...params, page: 1 };
		newParams.searchStatus = values.searchStatus;
		newParams.search = values.search;
		const sort = values.sort;
		if (sort === 'no-sort') {
			newParams.order = 'updatedAt desc';
		}
		if (sort === 'lp-percent') {
			newParams.order = 'liquidityFeePercent desc';
		}
		if (sort === 'start-time') {
			newParams.order = 'startTime desc';
		}
		if (sort === 'end-time') {
			newParams.order = 'endTime asc';
		}
		setParams(newParams);
		setOpen(false);
	};

	const onReset = () => {
		form.resetFields();
		const newParams = { ...params, page: 1 };
		delete newParams.search;
		newParams.searchStatus = QuerySearchStatus.ALL;
		newParams.order = 'updatedAt desc';
		setParams(newParams);
		setOpen(false);
	};

	return (
		<PopupUI title="Filter by" open={open} onClose={() => setOpen(false)}>
			<Form form={form} onFinish={onFinish}>
				<Form.Item name={'search'}>
					<StyledInput
						prefix={<SearchIc />}
						placeholder="Search by Project Name"
					/>
				</Form.Item>
				<Divider />
				<FilterLabel>Status:</FilterLabel>
				<Form.Item name={'searchStatus'} noStyle>
					<SortOptions>
						{STATUS_OPTIONS.map((option) => (
							<FilterOption
								onClick={() => form.setFieldValue('searchStatus', option.value)}
								key={option.value}
								style={{
									color: searchStatus === option.value ? '#fff' : '#757575',
									borderColor:
										searchStatus === option.value ? '#fff' : '#757575',
								}}
							>
								{option.label}
							</FilterOption>
						))}
					</SortOptions>
				</Form.Item>
				<FilterLabel style={{ marginTop: '16px' }}>Sort:</FilterLabel>
				<Form.Item name={'sort'} noStyle>
					<SortOptions>
						{SORT_OPTIONS.map((option) => (
							<FilterOption
								onClick={() => form.setFieldValue('sort', option.value)}
								key={option.value}
								style={{
									color: sort === option.value ? '#fff' : '#757575',
									borderColor: sort === option.value ? '#fff' : '#757575',
								}}
							>
								{option.label}
							</FilterOption>
						))}
					</SortOptions>
				</Form.Item>
				<Actions>
					<ResetBtn onClick={onReset}>Reset</ResetBtn>
					<ConfirmBtn htmlType="submit">Confirm</ConfirmBtn>
				</Actions>
			</Form>
		</PopupUI>
	);
};

export default FilterPopup;
