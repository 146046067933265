import React from 'react';
import CheckedProgressIc from '../Icons/CheckedProgressIc';
import { Progress } from 'antd';

const ProgressCircleUI = ({ percent }: { percent: number }) => {
	const format = () => {
		if (percent === 100) {
			return <CheckedProgressIc />;
		}
		return <></>;
	};
	return (
		<Progress
			type="circle"
			percent={percent}
			size={30}
			format={format}
			strokeColor="#15B643"
			trailColor={'transparent'}
			strokeWidth={8}
			strokeLinecap="butt"
		/>
	);
};

export default ProgressCircleUI;
