import React from 'react';

export default function WalletActiveIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={21}
			height={20}
			viewBox="0 0 21 20"
			fill="none"
		>
			<g clipPath="url(#clip0_606_2432)">
				<path
					d="M15.3164 4.61279L17.4023 2.52686C13.6211 -0.840332 7.90234 -0.844238 4.12109 2.52686L6.20703 4.61279C8.82422 2.39014 12.6953 2.39014 15.3164 4.61279Z"
					fill="white"
				/>
				<path
					d="M18.2344 3.35938L16.1484 5.44531C16.4219 5.76953 16.6641 6.11328 16.8711 6.47266C18.332 9.00781 18.0938 12.2617 16.1484 14.5586L18.2344 16.6445C21.6016 12.8633 21.6055 7.14062 18.2344 3.35938Z"
					fill="white"
				/>
				<path
					d="M6.20313 15.3867L4.11719 17.4727C7.89844 20.8398 13.6172 20.8438 17.3984 17.4727L15.3125 15.3867C12.6953 17.6094 8.82422 17.6094 6.20313 15.3867Z"
					fill="white"
				/>
				<path
					d="M4.64844 6.47266C4.66406 6.44922 4.91797 5.98437 5.37109 5.44531L3.28516 3.35938C-0.0820308 7.14062 -0.0859373 12.8594 3.28516 16.6406L5.37109 14.5547C3.42578 12.2617 3.18359 9.00391 4.64844 6.47266Z"
					fill="white"
				/>
				<path
					d="M11.6069 14.0618V14.6694C11.6069 14.8531 11.4558 15 11.2669 15H10.2331C10.0442 15 9.8931 14.8531 9.8931 14.6694V14.0618C8.78034 13.7846 7.9389 12.9866 7.77748 12.0083C7.74657 11.8314 7.89425 11.6678 8.07971 11.6678H9.21651C9.34015 11.6678 9.45693 11.7412 9.49814 11.8548C9.63552 12.2154 10.161 12.5025 10.7483 12.5025C11.4317 12.5025 12.0328 12.1119 12.0328 11.6678C12.0328 11.2237 11.4317 10.8331 10.7483 10.8331C9.09631 10.8331 7.75 9.71119 7.75 8.33222C7.75 7.20367 8.65326 6.24541 9.8931 5.93823V5.33055C9.8931 5.14691 10.0442 5 10.2331 5H11.2669C11.4558 5 11.6069 5.14691 11.6069 5.33055V5.93823C12.7197 6.21536 13.5611 7.01336 13.7225 7.99165C13.75 8.16861 13.6058 8.33222 13.4203 8.33222H12.2835C12.1598 8.33222 12.0431 8.25876 12.0019 8.14524C11.8645 7.78464 11.339 7.4975 10.7517 7.4975C10.0683 7.4975 9.46723 7.88815 9.46723 8.33222C9.46723 8.77629 10.0683 9.16695 10.7517 9.16695C12.4037 9.16695 13.75 10.2888 13.75 11.6678C13.75 12.7963 12.8433 13.7546 11.6069 14.0618Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_606_2432">
					<rect
						width={20}
						height={20}
						fill="white"
						transform="translate(0.75)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
