import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { shortenAddress } from '../../utils/common';
import {
	useTonAddress,
	useTonConnectUI,
	useTonWallet,
} from '@tonconnect/ui-react';
import DisconnectWalletIc from '../Icons/DisconnectWalletIc';
import {
	OKX_CONNECT_ERROR_CODES,
	OKXConnectError,
	Wallet,
} from '@okxconnect/tonsdk';
import { toast } from 'react-toastify';
import PopupUI from './Popup/PopupUI';
import { QRCode } from 'antd';
import tonService from '../../service/tonService';
import BigNumber from 'bignumber.js';
import TonWeb from 'tonweb';
import { setOkxWalletInfo } from '../../redux/reducers/wallet.slice';
import { UserRequest } from '../../service/user.request';
import { fetchUserProfile } from '../../redux/reducers/user.slice';
import { onCopy } from '../../utils/copy';

enum TypeWallet {
	TON = 'TON',
	OKX = 'OKX',
}

const convertFriendlyAddressOkx = (rawTonAddress: string) => {
	const tonweb = new TonWeb();
	const address = new tonweb.utils.Address(rawTonAddress);
	const userFriendlyAddress = address.toString(true, true, false);
	return userFriendlyAddress;
};

const ConnectWallet = ({
	isShowBalance,
	isShowTitle,
}: {
	isShowBalance?: boolean;
	isShowTitle?: boolean;
}) => {
	const okxTonConnect = useAppSelector((state) => state.wallet.okxTonConnect);
	const okxWalletInfo = useAppSelector((state) => state.wallet.okxWalletInfo);
	const dispatch = useAppDispatch();
	const tonWallet = useTonWallet();
	const [tonConnectUi] = useTonConnectUI();
	const userFriendlyAddress = useTonAddress();
	const [balanceTon, setBalanceTon] = useState('0');
	const [uriOkx, setUriOkx] = useState('');
	const [isOpenPopupOkx, setIsOpenPopupOkx] = useState(false);
	const loading = useAppSelector((state) => state.user.isLoading);
	const user = useAppSelector((state) => state.user);

	const linkWallet = async (walletAddress: string) => {
		try {
			const req = new UserRequest();
			await req.linkWallet(walletAddress);
			dispatch(fetchUserProfile());
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (!loading && !user.walletAddress) {
			if (!userFriendlyAddress && !okxWalletInfo?.account?.address) return;
			const userAddress =
				okxWalletInfo?.account?.address || userFriendlyAddress;
			linkWallet(userAddress);
		}
	}, [userFriendlyAddress, user, loading, okxWalletInfo?.account?.address]);

	const connectOKXWallet = async () => {
		if (okxTonConnect) {
			const ua = navigator.userAgent;
			const isIOS = /iphone|ipad|ipod|ios/i.test(ua);
			const isAndroid = /android|XiaoMi|MiuiBrowser/i.test(ua);
			const isMobile = isIOS || isAndroid;
			const isOKApp = /OKApp/i.test(ua);

			const params = {
				tonProof: 'signmessage',
				redirect: 'tg://resolve',
			};

			if (isMobile && !isOKApp) {
				try {
					await okxTonConnect.connect({
						...params,
						openUniversalLink: true,
					});
				} catch (error) {
					if (error instanceof OKXConnectError) {
						if (error.code === OKX_CONNECT_ERROR_CODES.USER_REJECTS_ERROR) {
							toast.error('User reject');
						} else if (
							error.code === OKX_CONNECT_ERROR_CODES.ALREADY_CONNECTED_ERROR
						) {
							toast.error('Already connected');
						} else {
							toast.error('Unknown error happened');
						}
					} else {
						toast.error('Unknown error happened');
					}
				}
			} else {
				const uri = await okxTonConnect.connect({
					...params,
					openUniversalLink: false,
				});
				setUriOkx(uri);
				setIsOpenPopupOkx(true);
			}
		}
	};

	const onDisconnectWallet = () => {
		if (okxTonConnect) okxTonConnect.disconnect();
		if (tonWallet) tonConnectUi.disconnect();
	};

	const fetchBalanceTon = async (address: string) => {
		try {
			const response = await tonService.getTonBalance(address);
			const balanceInTON = new BigNumber(response.data.balance)
				.dividedBy('1e9')
				.toString();
			setBalanceTon(balanceInTON || '0');
		} catch (error: any) {
			console.log(error);
		}
	};

	useEffect(() => {
		const address = userFriendlyAddress || okxWalletInfo?.account.address;
		if (!address || !isShowBalance) return;
		fetchBalanceTon(address);
	}, [tonWallet, okxWalletInfo, isShowBalance]);

	useEffect(() => {
		if (okxTonConnect) {
			okxTonConnect.onStatusChange(
				(walletInfo: Wallet | null) => {
					dispatch(setOkxWalletInfo(walletInfo));
					if (!!walletInfo) {
						setIsOpenPopupOkx(false);
					}
				},
				(err: OKXConnectError) => {
					console.log('Connection status:', err);
				}
			);
			if (okxTonConnect.wallet)
				dispatch(setOkxWalletInfo(okxTonConnect.wallet));
		}
	}, [okxTonConnect]);

	const renderConnectedWallet = (type: TypeWallet) => {
		if (type === TypeWallet.TON) {
			return (
				<WalletWrapper>
					<WalletContainer>
						<WalletItem>
							<WalletItemContent onClick={() => onCopy(userFriendlyAddress)}>
								<WalletTokenIconAddress
									src={'/images/tokens/TON.svg'}
									alt="token"
								/>
								{shortenAddress(userFriendlyAddress)}
							</WalletItemContent>
							<DisconnectWalletIc onClick={onDisconnectWallet} />
						</WalletItem>
						{isShowBalance && (
							<WalletItemBalance>
								<WalletItemContent>
									<WalletTokenIconBalance
										src={'/images/tokens/TON.svg'}
										alt="token"
									/>{' '}
									TON
								</WalletItemContent>

								<WalletBalance>{balanceTon}</WalletBalance>
							</WalletItemBalance>
						)}
					</WalletContainer>
				</WalletWrapper>
			);
		}

		if (type === TypeWallet.OKX) {
			return (
				<WalletWrapper>
					<WalletContainer>
						<WalletItem>
							<WalletItemContent
								onClick={() =>
									onCopy(
										convertFriendlyAddressOkx(
											okxWalletInfo?.account.address || ''
										)
									)
								}
							>
								<WalletTokenIconAddress
									src={'images/tokens/OKX.svg'}
									alt="token"
								/>{' '}
								{okxWalletInfo &&
									shortenAddress(
										convertFriendlyAddressOkx(okxWalletInfo.account.address)
									)}
							</WalletItemContent>
							<DisconnectWalletIc onClick={onDisconnectWallet} />
						</WalletItem>
						{isShowBalance && (
							<WalletItemBalance>
								<WalletItemContent>
									<WalletTokenIconBalance
										src={'images/tokens/TON.svg'}
										alt="token"
									/>{' '}
									{'TON'}
								</WalletItemContent>

								<WalletBalance>{balanceTon}</WalletBalance>
							</WalletItemBalance>
						)}
					</WalletContainer>
				</WalletWrapper>
			);
		}

		return <></>;
	};

	return (
		<>
			<Wrapper>
				{tonWallet || okxWalletInfo ? (
					renderConnectedWallet(tonWallet ? TypeWallet.TON : TypeWallet.OKX)
				) : (
					<Container>
						{isShowTitle && (
							<ConnectWalletTitle>Connect Wallet</ConnectWalletTitle>
						)}
						<ButtonGroup>
							<ButtonConnectWallet onClick={() => tonConnectUi.openModal()}>
								<WalletTokenIcon src="/images/tokens/TON.svg" alt="ton" /> TON
								Wallet
							</ButtonConnectWallet>

							<ButtonConnectWallet
								id="button-connect-wallet-okx"
								onClick={() => {
									connectOKXWallet();
								}}
							>
								<WalletTokenIcon src="/images/tokens/OKX.svg" alt="ton" /> OKX
								Wallet
							</ButtonConnectWallet>
						</ButtonGroup>
					</Container>
				)}
			</Wrapper>
			<PopupUI
				title="Connect OKX Wallet"
				open={isOpenPopupOkx}
				onClose={() => {
					setIsOpenPopupOkx(false);
				}}
			>
				<QRCodeWrapper>
					<QRCodeContainer>
						<QRCode size={220} value={uriOkx} />
					</QRCodeContainer>
					<QRCodeText>Scan QR Code</QRCodeText>
				</QRCodeWrapper>
			</PopupUI>
		</>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const ButtonGroup = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 12px;
	padding: 0 16px;
	width: 100%;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 8px;
`;

const ConnectWalletTitle = styled.div`
	color: var(--Native-text_color, #fff);
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 157.143% */
`;

const ButtonConnectWallet = styled.div`
	display: flex;
	width: 100%;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 8px;

	border-radius: 10px;
	border: 1px solid #15488d;
	background: #082954;

	color: var(--Web-White, var(--Native-text_color, #fff));
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px; /* 162.5% */
	cursor: pointer;
	transition: 0.2s all ease-in-out;

	&:hover {
		background: #15488d;
	}
`;

const WalletTokenIcon = styled.img`
	width: 26px;
	height: 26px;
	flex-shrink: 0;
`;

const WalletWrapper = styled.div`
	padding: 0 16px;
	width: 100%;
`;

const WalletContainer = styled.div`
	display: flex;
	padding: 0 12px;
	flex-direction: column;
	justify-content: center;

	border-radius: 10px;
	border: 1px solid #15488d;
	background: rgba(8, 41, 84, 0.7);
	backdrop-filter: blur(13px);
`;
const WalletItem = styled.div`
	color: var(--Web-White, var(--Native-text_color, #fff));
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px;
	padding: 8px 0;

	display: flex;
	justify-content: space-between;
	align-items: center;

	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const WalletItemBalance = styled.div`
	color: var(--Web-White, var(--Native-text_color, #fff));
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px; /* 185.714% */
	padding: 8px 0;

	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const WalletTokenIconAddress = styled.img`
	width: 32px;
	height: 32px;
	flex-shrink: 0;
	margin-right: 8px;
`;

const WalletTokenIconBalance = styled.img`
	width: 20px;
	height: 20px;
	flex-shrink: 0;
	margin-right: 6px;
`;

const WalletItemContent = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;

const WalletBalance = styled.div`
	color: #fec424;
	text-align: right;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px; /* 185.714% */
`;

const QRCodeWrapper = styled.div`
	padding: 20px 0;
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: center;
	width: 100%;
`;

const QRCodeContainer = styled.div`
	background: #fff;
	width: fit-content;
	margin: 0 auto;
	border-radius: 10px;
`;

const QRCodeText = styled.div`
	display: flex;
	max-width: 266px;
	width: 100%;
	margin: 0 auto;
	padding: 10px 16px;
	justify-content: center;
	align-items: center;

	border-radius: 8px;
	border: 1.4px solid #767677;
	background: linear-gradient(
			319deg,
			#fff -1.9%,
			rgba(255, 255, 255, 0) 52.62%,
			#fff 105%
		),
		#464b51;
	background-blend-mode: soft-light, normal;

	color: rgba(255, 255, 255, 0.5);
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

export default ConnectWallet;
