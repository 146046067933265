import { useMemo } from 'react';
import { config } from '../../config';
import {
	POOL_STATUS,
	PoolContentKycStatus,
	SubTag,
} from '../../constants/launchpad';
import { formatToken } from '../../utils/common';
import {
	getStatusColor,
	getStatusTag,
	getSubtagColor,
} from '../../utils/launchpad';
import PersonIc from '../Icons/PersonIc';
import {
	Affiliate,
	Avatar,
	AvatarBlock,
	BorderRight,
	BottomBlock,
	CardHeader,
	CardWrapper,
	CountdownBlock,
	CountdownItem,
	Dot,
	Label,
	Line,
	LiquidityBlock,
	LiquidityValue,
	Name,
	NameInfo,
	Progress,
	ProgressLabel,
	ProgressWrapper,
	Rate,
	Row,
	SoftCap,
	SoftCapValue,
	Status,
	StyledButtonUI,
	Subtags,
	SubTagWrapper,
	Time,
	TimeBlock,
	Unit,
} from './Card.styled';
import Countdown from 'react-countdown';
import BigNumber from 'bignumber.js';

interface IDOCardProps {
	className?: string;
	id: string;
	address: string;
	allowEarlyRefund: boolean;
	projectName: string;
	image: string;
	startTime: number;
	endTime: number;
	listingTime: number;
	price: {
		price: number;
		currency: string;
	};
	softCap: number;
	liquidity: number;
	offered: number;
	soldToken: number;
	affiliate: number;
	symbol: string;
	status: number;
	kycStatus: string;
	lpAddress: string;
}
export const IDOCard: React.FC<IDOCardProps> = ({
	address,
	status,
	startTime,
	endTime,
	lpAddress,
	affiliate,
	projectName,
	symbol,
	price,
	liquidity,
	offered,
	soldToken,
	softCap,
	listingTime,
	kycStatus,
	allowEarlyRefund,
	image,
	...props
}: IDOCardProps) => {
	const statusTag = useMemo(() => {
		return getStatusTag(status, startTime, endTime, lpAddress);
	}, [status, startTime, endTime, lpAddress]);

	const progress = useMemo(() => {
		const percent = new BigNumber(soldToken)
			.div(new BigNumber(softCap))
			.multipliedBy(100)
			.toNumber();
		return percent > 100 ? 100 : percent;
	}, [soldToken, softCap]);

	const title = useMemo(() => {
		if (new Date().valueOf() >= listingTime) return "Time's Up";
		if (new Date().valueOf() >= endTime) return 'Presale Listing In';
		if (new Date().valueOf() >= startTime) return 'Presale Ends In';
		return 'Presale Starts In';
	}, [startTime, endTime, listingTime]);

	const time = useMemo(() => {
		if (new Date().valueOf() >= endTime) return listingTime;
		if (new Date().valueOf() >= startTime) return endTime;
		return startTime;
	}, [startTime, endTime, listingTime]);

	return (
		<CardWrapper {...props}>
			<CardHeader>
				<Row>
					<Status
						color={getStatusColor(statusTag)}
						backgroundColor={`${getStatusColor(statusTag)}1A`}
					>
						<Dot color={getStatusColor(statusTag)} />
						{statusTag}
					</Status>
					<Affiliate>
						<PersonIc /> {affiliate}%
					</Affiliate>
				</Row>
				<Subtags>
					{kycStatus === PoolContentKycStatus.WAITING_TO_KYC && (
						<SubTagWrapper color={getSubtagColor(SubTag.WAITING_TO_KYC)}>
							{SubTag.WAITING_TO_KYC}
						</SubTagWrapper>
					)}
					{kycStatus === PoolContentKycStatus.KYC_APPROVED && (
						<SubTagWrapper color={getSubtagColor(SubTag.KYC)}>
							{SubTag.KYC}
						</SubTagWrapper>
					)}
					{allowEarlyRefund && (
						<SubTagWrapper color={getSubtagColor(SubTag.REFUNDABLE)}>
							{SubTag.REFUNDABLE}
						</SubTagWrapper>
					)}
				</Subtags>
			</CardHeader>
			<AvatarBlock>
				<Avatar src={image} alt={projectName} />
				<NameInfo>
					<Name>{projectName}</Name>
					<Rate>
						1 {symbol} = {formatToken(price.price, 6)} {price.currency}
					</Rate>
				</NameInfo>
			</AvatarBlock>
			<SoftCap>
				<Label>Soft Cap</Label>
				<SoftCapValue>
					{formatToken(softCap)} {price.currency}
				</SoftCapValue>
			</SoftCap>
			<LiquidityBlock>
				<Row>
					<Label>Liquidity:</Label>
					<LiquidityValue>{formatToken(liquidity)}%</LiquidityValue>
				</Row>
				<Row>
					<Label>Offered:</Label>
					<LiquidityValue>
						{formatToken(offered, 6)} {symbol}
					</LiquidityValue>
				</Row>
			</LiquidityBlock>
			<ProgressLabel>Progress</ProgressLabel>
			<ProgressWrapper>
				<Line />
				<Progress width={progress} />
				<BorderRight width={progress} />
			</ProgressWrapper>
			<Row>
				<ProgressLabel>
					{formatToken(soldToken, 6)} {price.currency}
				</ProgressLabel>
				<ProgressLabel>
					{formatToken(softCap, 6)} {price.currency}
				</ProgressLabel>
			</Row>
			<BottomBlock>
				<TimeBlock>
					{status === POOL_STATUS.APPROVED && (
						<>
							<ProgressLabel>{title}:</ProgressLabel>
							<Countdown date={new Date(time)} renderer={renderer} />{' '}
						</>
					)}
				</TimeBlock>

				<StyledButtonUI
					onClick={() =>
						window.open(`${config.padton_website}/launchpad/${address}`)
					}
				>
					View
				</StyledButtonUI>
			</BottomBlock>
		</CardWrapper>
	);
};

export default IDOCard;

const renderer = ({ days, hours, minutes, seconds }: any) => {
	return (
		<>
			<CountdownBlock>
				<CountdownItem>
					<Time>{days}</Time>
					<Unit>Days</Unit>
				</CountdownItem>
				<CountdownItem>
					<Time>{hours}</Time>
					<Unit>Hours</Unit>
				</CountdownItem>
				<CountdownItem>
					<Time>{minutes}</Time>
					<Unit>Mins</Unit>
				</CountdownItem>
				<CountdownItem>
					<Time>{seconds}</Time>
					<Unit>Secs</Unit>
				</CountdownItem>
			</CountdownBlock>
		</>
	);
};
