import styled from 'styled-components';
import SectionTitle from '../UI/SectionTitle';
import IDOCard from './Card';
import { fadeIn } from '../../constants/css';
import { Button, Input } from 'antd';

export const Wrapper = styled.div`
	animation: ${fadeIn} 0.5s ease;
	margin-top: 18px;
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-right: 16px;
	box-sizing: border-box;
`;
export const StyledSectionTitle = styled(SectionTitle)`
	margin: 0;
`;

export const Cards = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 0 16px;
	box-sizing: border-box;
	margin-top: 18px;
	animation: ${fadeIn} 0.5s ease;
	&:last-child {
		margin-bottom: 30px;
	}
`;

export const StyledIDOCard = styled(IDOCard)`
	width: 100%;
	height: 389px;
`;
export const FilterBlock = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;

	color: #d5d5d5;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 21px;
	svg {
		transform: rotate(90deg);
	}
`;

export const StyledInput = styled(Input)`
	border-radius: 10px !important;
	border: 0.5px solid #757575 !important;
	background: #06244b !important;
	input {
		color: #ffffff !important;
	}
	::placeholder {
		color: #b4b4b4 !important;
	}
`;

export const Divider = styled.div`
	margin: 16px 0;
	height: 1px;
	width: 100%;
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.2) 50%,
		rgba(255, 255, 255, 0) 100%
	);
`;

export const FilterLabel = styled.div`
	color: #fff;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 26px;
`;

export const SortOptions = styled.div`
	margin-top: 8px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;
`;

export const FilterOption = styled.div`
	height: 28px;
	box-sizing: border-box;
	display: flex;
	height: 28px;
	padding: 2px 10px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 5px;
	border: 0.5px solid;
	background: rgba(0, 0, 0, 0.16);
	text-align: center;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 500;
	line-height: 26px;
`;

export const Actions = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	margin-top: 16px;
	gap: 16px;
`;

export const ResetBtn = styled(Button)`
	display: flex;
	width: 167px;
	height: 38px;
	padding: 12px 20px;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	border-radius: 10px;
	border: 1px solid #00abeb;
	color: #00abeb;
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	background-color: transparent;
`;

export const ConfirmBtn = styled(ResetBtn)`
	background: #00abeb;
	color: #fff;
	border: 1px solid #00abeb;
`;
export const Loader = styled.div``;
export const LoadingWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
`;
