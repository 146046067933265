import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ReferralRequest from '../../service/referral.request';

export interface Profile {
	id: string;
	userId: string;
	code: string;
	referer: string;
	networks: string[];
	isActive: boolean;
	createdAt: string;
	updatedAt: string;
	info: any;
}

export const fetchReferralProfile = createAsyncThunk(
	'referral/fetchReferralProfile',
	async (_, { rejectWithValue }) => {
		try {
			const req = new ReferralRequest();
			const res = await req.getProfile();
			return res.data?.data;
		} catch (error: any) {
			console.log('error', error?.response?.data);
			return rejectWithValue(error?.response?.data);
		}
	}
);

interface ReferralState {
	profile: Profile | null;
}

const initialState = { profile: null } as ReferralState;

const referralSlice = createSlice({
	name: 'referral',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchReferralProfile.fulfilled, (state, action) => {
			if (action.payload) {
				state.profile = action.payload;
			}
		});
	},
});

export const {} = referralSlice.actions;
export default referralSlice.reducer;
