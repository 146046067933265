import { OKXTonConnect, Wallet } from '@okxconnect/tonsdk';
import { OKXUniversalProvider } from '@okxconnect/universal-provider';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WalletState {
	wallets: WalletResponse[];
	currencies: Currency[];
	isLoadingWallet: boolean;
	providerOkx: OKXUniversalProvider | null;
	okxTonConnect: OKXTonConnect | null;
	okxWalletInfo: Wallet | null;
}

const initialState = {
	wallets: [],
	currencies: [],
	isLoadingWallet: true,
	providerOkx: null,
	okxTonConnect: null,
	okxWalletInfo: null,
} as WalletState;

const walletSlice = createSlice({
	name: 'wallet',
	initialState,
	reducers: {
		setWallets(state, action: PayloadAction<any>) {
			state.wallets = action.payload;
			return state;
		},
		setCurrencies(state, action: PayloadAction<any>) {
			state.currencies = action.payload;
			return state;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.isLoadingWallet = action.payload;
			return state;
		},
		setProviderOkx(state, action: PayloadAction<OKXUniversalProvider | null>) {
			state.providerOkx = action.payload;
			return state;
		},
		setOkxTonConnect(state, action: PayloadAction<OKXTonConnect | null>) {
			state.okxTonConnect = action.payload;
			return state;
		},
		setOkxWalletInfo(state, action: PayloadAction<Wallet | null>) {
			state.okxWalletInfo = action.payload;
			return state;
		},
	},
});

export const {
	setWallets,
	setCurrencies,
	setLoading,
	setProviderOkx,
	setOkxTonConnect,
	setOkxWalletInfo,
} = walletSlice.actions;
export default walletSlice.reducer;
