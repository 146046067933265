import { formatLotteryResult, formatStartDate } from '../../../utils/lottery';
import {
	CenterBlock,
	DateTime,
	PoolInfo,
	UserAvatarWrapper,
	Wrapper,
	Avatar,
	Crown,
	CenterRightBlock,
	UserName,
	WinnerTicket,
	CircleResult,
	Prize,
	PrizeNumber,
	TokenImage,
	AllParticipantsBlock,
	Title,
	FireGif,
	CheckingResultBlock,
	Label,
	ResultLink,
	Participants,
	ParticipantItem,
	ParticipantLeftBlock,
	ParticipantAvatar,
	ParticipantInfo,
	ParticipantName,
	ParticipatedAt,
	ParticipantTicket,
	TicketNumber,
	Divider,
	LoadingWrapper,
	Loader,
} from './PoolResult.styled';
import { DEFAULT_AVATAR, QUERY_KEYS } from '../../../constants';
import {
	formatToken,
	getName,
	getStaleTime,
	hideName,
} from '../../../utils/common';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Flex } from 'antd';
import { LotteryRequest } from '../../../service/lottery.request';
import { useSearchParams } from 'react-router-dom';
import Notfound from '../../UI/NotFound';
import { useQueryClient } from '@tanstack/react-query';
import Loading from '../../UI/Loading';
import { useAppSelector } from '../../../redux/store';

const PoolResult = () => {
	const queryClient = useQueryClient();
	const lotteryRequest = new LotteryRequest();
	const [winnerTicket, setWinnerTicket] = useState<string>('3434');
	const [tickets, setTickets] = useState<Ticket[]>([]);
	const [searchParams] = useSearchParams();
	const poolId = searchParams.get('id');
	const [loading, setLoading] = useState(true);
	const [totalPage, setTotalPage] = useState(1);
	const loader = useRef<HTMLDivElement>(null);
	const [poolDetail, setPoolDetail] = useState<LotteryPool>();
	const [isLoadingPoolDetail, setIsLoadingPoolDetail] = useState(true);
	const currencies = useAppSelector((state) => state.wallet.currencies);
	const [params, setParams] = useState<GetTicketsParams>({
		order: 'createdAt desc',
		poolId: poolId as string,
		page: 1,
		pageSize: 10,
	});

	const poolDetailCurrency = useMemo(() => {
		if (!poolDetail) return;
		return currencies.find(
			(cur) => cur.symbol === poolDetail.prizeCurrencySymbol
		);
	}, [poolDetail]);

	useEffect(() => {
		setParams((params) => ({
			...params,
			poolId: poolId as string,
		}));
		getPoolDetail(poolId as string);
	}, [poolId]);

	useEffect(() => {
		getTickets(params);
	}, [params]);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && !loading) {
					setParams((params) => ({
						...params,
						page: params.page ? params.page + 1 : 1,
					}));
				}
			},
			{ threshold: 1 }
		);
		if (loader.current) {
			observer.observe(loader.current);
		}
		return () => {
			if (loader.current) {
				observer.unobserve(loader.current);
			}
		};
	}, [loading]);

	const getPoolDetail = async (poolId: string) => {
		if (!poolId) return;
		try {
			setIsLoadingPoolDetail(true);
			const res = await queryClient.fetchQuery({
				queryKey: [QUERY_KEYS.GET_POOL_DETAIL, poolId],
				queryFn: () => lotteryRequest.getPoolDetail(poolId),
				staleTime: getStaleTime(10),
			});
			setPoolDetail(res.data.data);
			setWinnerTicket(formatLotteryResult(Number(res.data.data?.result)));
		} catch (error: any) {
			console.error(error);
		} finally {
			setIsLoadingPoolDetail(false);
		}
	};
	const getTickets = async (params: GetTicketsParams) => {
		if (!params.poolId) return;
		try {
			if (params.page && params.page > totalPage) return;
			setLoading(true);
			const res = await lotteryRequest.getTickets(params);
			const data = res.data.data.map((ticket: Ticket) => ({
				...ticket,
				ticket: formatLotteryResult(Number(ticket.ticket)),
			}));
			const newData = params.page === 1 ? data : [...tickets, ...data];
			setTickets(newData);
			setTotalPage(res.data.meta.totalPage || 1);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	return (
		<Wrapper>
			{isLoadingPoolDetail ? (
				<Loading />
			) : (
				<>
					{poolDetail ? (
						<>
							<PoolInfo>
								<DateTime>{formatStartDate(poolDetail.createdAt)}</DateTime>
								<Flex style={{ flexDirection: 'column' }}>
									<CenterBlock>
										<UserAvatarWrapper>
											<Avatar
												src={
													!!poolDetail.winner?.avatar
														? poolDetail.winner?.avatar
														: DEFAULT_AVATAR
												}
											/>
											<Crown src="/images/winner-crown.svg" />
										</UserAvatarWrapper>
										<CenterRightBlock>
											<UserName>
												{!!poolDetail.winner
													? hideName(
															getName(
																poolDetail.winner.firstName,
																poolDetail.winner.lastName,
																poolDetail.winner.username
															)
													  )
													: '--'}
											</UserName>
											{!!poolDetail.result && (
												<WinnerTicket>
													<CircleResult>{winnerTicket.charAt(0)}</CircleResult>
													<CircleResult>{winnerTicket.charAt(1)}</CircleResult>
													<CircleResult>{winnerTicket.charAt(2)}</CircleResult>
													<CircleResult>{winnerTicket.charAt(3)}</CircleResult>
												</WinnerTicket>
											)}
											<Prize>
												Prize:{' '}
												<PrizeNumber>
													{formatToken(poolDetail.prizeAmount)}{' '}
												</PrizeNumber>
												<TokenImage
													src={poolDetailCurrency?.logo}
													alt="token-image"
												/>
											</Prize>
										</CenterRightBlock>
									</CenterBlock>
									{poolDetail?.info?.resultLink && (
										<CheckingResultBlock>
											<Label>Checking results at:</Label>
											<ResultLink
												href={poolDetail.info.resultLink}
												target="_blank"
												rel="noopenner"
											>
												{poolDetail.info.resultLink}
											</ResultLink>
										</CheckingResultBlock>
									)}
								</Flex>
							</PoolInfo>
							{loading && params.page == 1 ? (
								<LoadingWrapper>
									<img
										src="/images/loading.svg"
										alt="loading"
										width={'30px'}
										height={'30px'}
									/>
								</LoadingWrapper>
							) : tickets.length > 0 ? (
								<>
									<AllParticipantsBlock>
										<Title>
											All participants{' '}
											<FireGif src="/gifs/fire.gif" alt="fire" />
										</Title>
										<Participants>
											{tickets.map((ticket: Ticket) => (
												<ParticipantItem key={ticket.id}>
													<ParticipantLeftBlock>
														<ParticipantAvatar
															src={ticket.user?.avatar || DEFAULT_AVATAR}
															alt="avatar"
														/>
														<ParticipantInfo>
															<ParticipantName>
																{hideName(
																	getName(
																		ticket.user?.firstName,
																		ticket?.user?.lastName,
																		ticket?.user?.username
																	)
																)}
															</ParticipantName>
															<ParticipatedAt>
																{formatStartDate(ticket.createdAt)}
															</ParticipatedAt>
														</ParticipantInfo>
													</ParticipantLeftBlock>
													<ParticipantTicket>
														<TicketNumber>
															{ticket.ticket.charAt(0)}
														</TicketNumber>
														<TicketNumber>
															{ticket.ticket.charAt(1)}
														</TicketNumber>
														<TicketNumber>
															{ticket.ticket.charAt(2)}
														</TicketNumber>
														<TicketNumber>
															{ticket.ticket.charAt(3)}
														</TicketNumber>
													</ParticipantTicket>
													<Divider />
												</ParticipantItem>
											))}
										</Participants>
									</AllParticipantsBlock>
									{params.page && params.page < totalPage && (
										<LoadingWrapper>
											<img
												src="/images/loading.svg"
												alt="loading"
												width={'30px'}
												height={'30px'}
											/>
										</LoadingWrapper>
									)}
									<Loader ref={loader} />
								</>
							) : (
								<Notfound
									content={
										<div>
											No participant yet. <br />
										</div>
									}
								/>
							)}
						</>
					) : (
						<Notfound content={<div>Pool not found</div>} />
					)}
				</>
			)}
		</Wrapper>
	);
};

export default PoolResult;
