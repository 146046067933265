import { ConfigProvider } from 'antd';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { customTheme } from './config/theme.config';
import './index.css';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import { router } from './routes';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Buffer } from 'buffer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

window.Buffer = Buffer;
const env = process.env.REACT_APP_ENV;
if (env === 'production') {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [Sentry.browserTracingIntegration()],
	});
}
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<>
		<QueryClientProvider client={queryClient}>
			<TonConnectUIProvider
				manifestUrl={`${window?.location?.origin}${process.env.REACT_APP_TON_CONNECT_MANIFEST_PATH}`}
				uiPreferences={{ theme: THEME.DARK }}
			>
				<Provider store={store}>
					<ConfigProvider theme={customTheme}>
						<RouterProvider router={router} />
						<ToastContainer
							position="top-right"
							autoClose={3000}
							newestOnTop={false}
							rtl={false}
							pauseOnFocusLoss
							pauseOnHover
							hideProgressBar
							theme="colored"
							className={'toast-wrapper'}
							closeButton={false}
						/>
					</ConfigProvider>
				</Provider>
			</TonConnectUIProvider>
		</QueryClientProvider>
	</>
);
reportWebVitals();
