import React from 'react';
import styled from 'styled-components';
import PlusIc from '../Icons/PlusIc';
import SectionTitle from '../UI/SectionTitle';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import useGameInfo from '../../hooks/useGameInfo';
import { shortenNumber } from '../../utils/common';

const GameHot = () => {
	const navigate = useNavigate();
	const { farmBalance, totalSpin } = useGameInfo();
	return (
		<Wrapper>
			<LayerBlur />
			<Header>
				<SectionTitle text="Game Hot" />
				<ViewAllLink onClick={() => navigate(ROUTES.EARN)}>
					View All
				</ViewAllLink>
			</Header>

			<Container>
				<Card bgUrl="/images/landing/lottery-card.png">
					<CardContainer>
						<CardContent>
							<CardTitle>Lottery Pools</CardTitle>
							<CardText style={{ color: '#F5D245', fontWeight: 500 }}>
								100% winning chance, <br /> daily rewards.
							</CardText>
						</CardContent>

						<CardButton onClick={() => navigate(ROUTES.LOTTERY)}>
							<PlusIc /> <span>Play Now</span>
						</CardButton>
					</CardContainer>
				</Card>

				<Card bgUrl="/images/landing/farm-card.png" isContentRight>
					<CardContainer isContentRight>
						<CardContent isContentRight>
							<CardTitle>Farming Game</CardTitle>
							<CartTextFarm isContentRight>
								Farm and Earn! The More You Farm, The More You Gain!
							</CartTextFarm>
							<CardText isContentRight>
								Total Earned:{' '}
								<CardValue>
									{shortenNumber(farmBalance)}{' '}
									<Token src="/images/tokens/patc.svg" alt="patc" />{' '}
								</CardValue>{' '}
							</CardText>
						</CardContent>

						<CardButton onClick={() => navigate(ROUTES.FARM)}>
							<PlusIc /> <span>Farm Now</span>
						</CardButton>
					</CardContainer>
				</Card>

				<Card bgUrl="/images/landing/lucky-card.png">
					<CardContainer>
						<CardContent>
							<CardTitle>Lucky Spin</CardTitle>
							<CardText>Test Your Luck and Win Exclusive Tokens!</CardText>
							<CardText>
								Your Spins: <CardValue>{shortenNumber(totalSpin)}</CardValue>{' '}
							</CardText>
						</CardContent>

						<CardButton onClick={() => navigate(ROUTES.LUCKY_SPIN)}>
							<PlusIc /> <span>Play Now</span>
						</CardButton>
					</CardContainer>
				</Card>
			</Container>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: relative;
`;

const LayerBlur = styled.div`
	position: absolute;
	top: -100px;
	left: -180px;
	z-index: -1;
	width: 326px;
	height: 327px;
	flex-shrink: 0;

	border-radius: 327px;
	background: #0084ff;
	filter: blur(100px);
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Container = styled.div`
	padding: 0 16px;
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

const ViewAllLink = styled.div`
	color: #d5d5d5;
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px; /* 175% */
	padding: 0 16px;
`;

const Card = styled.div<{ bgUrl: string; isContentRight?: boolean }>`
	background: url(${(props) => props.bgUrl});
	background-size: cover;
	width: 100%;
	min-height: 168px;
	border-radius: 12px;
	display: flex;
	justify-content: ${(props) => (props.isContentRight ? 'end' : 'start')};
`;

const CardContent = styled.div<{ isContentRight?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: ${(props) => (props.isContentRight ? 'end' : 'start')};
	gap: 4px;
	margin-bottom: 12px;
`;
const CardContainer = styled.div<{ isContentRight?: boolean }>`
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: ${(props) => (props.isContentRight ? 'end' : 'start')};
`;
const CardTitle = styled.div`
	color: var(--Web-White, var(--Native-text_color, #fff));
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;
const CardText = styled.div<{ isContentRight?: boolean }>`
	max-width: 170px;

	color: #b4b4b4;
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 166.667% */
	display: flex;
	text-align: ${(props) => (props.isContentRight ? 'right' : 'left')};
	justify-content: ${(props) => (props.isContentRight ? 'end' : 'start')};
	gap: 4px;
`;

const CartTextFarm = styled(CardText)`
	max-width: 195px;
`;

const CardValue = styled.div`
	color: #fff;
	font-weight: 500;
	display: flex;
	align-items: center;
	gap: 4px;
`;

const CardButton = styled.div`
	border-radius: 8px;
	border: 1.4px solid #7fc2eb;
	background: linear-gradient(
			319deg,
			#fff -1.9%,
			rgba(255, 255, 255, 0) 52.62%,
			#fff 105%
		),
		#007aff;
	background-blend-mode: soft-light, normal;

	color: var(--Native-text_color, #fff);
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	width: fit-content;

	display: flex;
	padding: 6px 16px;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	transition: 0.2s all ease-in-out;

	&:hover {
		background: #00abeb;
	}
`;

const Token = styled.img`
	width: 16px;
	height: 16px;
`;

export default GameHot;
