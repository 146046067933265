import React from 'react';

const SmallArrowRightIc = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={5}
			height={9}
			viewBox="0 0 5 9"
			fill="none"
		>
			<path
				d="M1 8L4 4.5L1 1"
				stroke="#A3A3A3"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default SmallArrowRightIc;
