export enum LAUNCHPAD_STATUS {
	ACTIVE = 'Active',
	UPCOMING = 'Upcoming',
	LISTING_END = 'Listing End',
	SALE_ENDED = 'Sale Ended',
	FINISHED = 'Finished',
	CANCELED = 'Canceled',
	KYC = 'KYC',
	WAITING_FOR_KYC = 'Waiting for KYC',
	WAITING_TO_REVIEW = 'Waiting to review',
	REJECTED = 'Rejected',
	LISTED = 'Listed',
}

export enum POOL_STATUS {
	NEW = 1,
	APPROVED = 2,
	FINISHED = 3,
	CANCELLED = 4,
	REJECTED = 5,
}

export enum SubTag {
	WAITING_TO_KYC = 'Waiting For Kyc',
	KYC = 'KYC',
	REFUNDABLE = 'Refundable',
	AMA = 'AMA',
	AUDIT = 'Audit',
}

export enum PoolContentKycStatus {
	NO_KYC = 'NO_KYC',
	WAITING_TO_KYC = 'WAITING_TO_KYC',
	KYC_APPROVED = 'KYC_APPROVED',
}
export enum QuerySearchStatus {
	ALL = 'all',
	WAITING_TO_KYC = 'waiting_to_kyc',
	WAITING_TO_REVIEW = 'waiting_to_review',
	KYC = 'kyc',
	UPCOMING = 'upcoming',
	ACTIVE = 'active',
	ENDED = 'ended',
	FINISHED = 'finished',
	CANCELED = 'canceled',
}
export const STATUS_OPTIONS = [
	{
		label: 'All',
		value: QuerySearchStatus.ALL,
	},
	{
		label: 'Waiting for KYC',
		value: QuerySearchStatus.WAITING_TO_KYC,
	},
	{
		label: 'Upcoming',
		value: QuerySearchStatus.UPCOMING,
	},
	{
		label: 'Active',
		value: QuerySearchStatus.ACTIVE,
	},
	{
		label: 'Sale Ended',
		value: QuerySearchStatus.ENDED,
	},
	{
		label: 'Finished',
		value: QuerySearchStatus.FINISHED,
	},
	{
		label: 'Canceled',
		value: QuerySearchStatus.CANCELED,
	},
];

export const SORT_OPTIONS = [
	{
		label: 'No Sort',
		value: 'no-sort',
	},
	{
		label: 'LP percent',
		value: 'lp-percent',
	},
	{
		label: 'Start time',
		value: 'start-time',
	},
	{
		label: 'End time',
		value: 'end-time',
	},
];
