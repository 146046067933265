import { AxiosResponse } from 'axios';
import BigNumber from 'bignumber.js';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { LoyaltyWalletRequest } from '../../service/loyalty-wallet.request';
import { getLoyaltyWalletReq } from '../actions/loyalty-wallet.action';
import { setLoyaltyBalance } from '../reducers/loyalty-wallet.slice';
import { RootState } from '../store';

function* getLoyaltyWalletSaga(): any {
	try {
		const balance = yield select(
			(state: RootState) => state.loyaltyWallet.balance
		);
		const req = new LoyaltyWalletRequest();
		const maxRetries = 5;
		const delayDuration = 2000; // 2 second
		let attempt = 0;
		let updated = false;

		while (attempt < maxRetries && !updated) {
			try {
				attempt += 1;
				const res: AxiosResponse<ResponseData<LoyaltyWalletResponse>> =
					yield call([req, 'getMyWallet']);
				const data = res.data.data;
				if (
					res.status === 200 &&
					!!data &&
					new BigNumber(data.availableBalance).comparedTo(balance) !== 0
				) {
					updated = true;
					yield put(setLoyaltyBalance(data.availableBalance));
				} else {
					throw new Error('Retryable error occurred');
				}
			} catch (error) {
				if (attempt < maxRetries) {
					yield delay(delayDuration);
				}
			}
		}
	} catch (error) {
		console.log(error);
	}
}
export function* loyaltyWalletSaga() {
	yield takeLatest(getLoyaltyWalletReq, getLoyaltyWalletSaga);
}
