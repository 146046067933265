import styled from 'styled-components';
import ProgressCircleUI from '../UI/ProgressCircle';
import { useEffect, useState } from 'react';
import { fadeIn } from '../../constants/css';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { EntryRequirementRequest } from '../../service/entry-requirement.request';
import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import { ENTRY_CODE } from '../../constants/mission';
import { formatNumber } from '../../utils/common';
import { useQueryClient } from '@tanstack/react-query';
import { IS_PASSED_REQUIREMENT, QUERY_KEYS } from '../../constants';
import { getLoyaltyWalletReq } from '../../redux/actions/loyalty-wallet.action';

const renderAgeText = (startAt: number) => {
	const now = new Date();
	const start = new Date(startAt);
	const diff = now.getTime() - start.getTime();
	const age = new Date(diff);
	const ageByYear = Math.abs(age.getUTCFullYear() - 1970);
	if (ageByYear === 0) {
		// calculate month
		const ageByMonth = Math.abs(age.getUTCMonth());
		return `${ageByMonth} ${ageByMonth > 1 ? 'months' : 'month'}`;
	}
	return `${ageByYear} ${ageByYear > 1 ? 'years' : 'year'}`;
};
const RequiredMission = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const [verifyAgeProgress, setVerifyAgeProgress] = useState(0);
	const [telegramPremiumProgress, setTelegramPremiumProgress] = useState(0);
	const [data, setData] = useState<any[]>([]);
	const user = useAppSelector((state: RootState) => state.user);
	const telePremiumCheck = data.find(
		(mission) => mission.code === ENTRY_CODE.TELE_PREMIUM_CHECK
	);

	const ageCheck = data.find(
		(mission) => mission.code === ENTRY_CODE.ACCOUNT_AGE_VERIFIED
	);

	useEffect(() => {
		fetchEntryRequirements();
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setVerifyAgeProgress((prev) => {
				if (prev < 100) {
					return prev + 1;
				}
				return prev;
			});
		}, 30);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			const interval = setInterval(() => {
				setTelegramPremiumProgress((prev) => {
					if (prev < 100) {
						return prev + 1;
					}
					return prev;
				});
			}, 30);
			return () => clearInterval(interval);
		}, 3000);
	}, []);

	const fetchEntryRequirements = async () => {
		try {
			const req = new EntryRequirementRequest();
			const res = await req.getRequirements();
			if (res) {
				const request = res.data?.data.map((item: any) =>
					req.verifyRequirement(item.id).then((res) => res.data?.data)
				);
				const verifyResponse = await Promise.all(request);
				if (verifyResponse && verifyResponse.length == res.data.data?.length) {
					setData(verifyResponse);
					localStorage.setItem(IS_PASSED_REQUIREMENT, user.id);
				}
				dispatch(getLoyaltyWalletReq());
			}
		} catch (error) {
			console.log(error);
		} finally {
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.GET_LOYALTY_REWARDS],
			});
		}
	};
	return (
		<Wrapper>
			<Layer />
			<Container>
				<ImageRocket src="/gifs/rocket.gif" alt="rocket" />
				<Content>
					<Title>Checking your Account</Title>
					<CheckList>
						{/* {telegramPremiumProgress >= 100 && ageCheck && telePremiumCheck ? (
							<> */}
						<CheckItem>
							<CheckItemContent>
								<CheckItemText type="small">
									{ageCheck && verifyAgeProgress >= 100
										? 'You’ve joined Telegram'
										: 'Account Age Verified'}
								</CheckItemText>
								{ageCheck?.info && verifyAgeProgress >= 100 && (
									<RewardWrapper>
										<CheckItemText type="title">
											{ageCheck?.info?.startAt
												? `${renderAgeText(ageCheck?.info?.startAt || 0)} ago `
												: '--'}
											<FireIcon src="/gifs/fire.gif" alt="fire" />
										</CheckItemText>
										<CheckItemText type="smallBold">
											<Reward>
												<span>Reward:</span>
												<Amount>
													<span>
														+{formatNumber(ageCheck?.info?.reward || 0)}
													</span>
													<ImageToken
														src="/images/tokens/patc.svg"
														alt="patc"
													/>{' '}
												</Amount>
											</Reward>
										</CheckItemText>
									</RewardWrapper>
								)}
							</CheckItemContent>
							<ProgressCircleUI percent={verifyAgeProgress} />
						</CheckItem>
						<CheckItem>
							<CheckItemContent>
								<CheckItemText type="title">
									{telePremiumCheck && telegramPremiumProgress >= 100 ? (
										<>
											<span>
												{telePremiumCheck?.info?.isPremium
													? 'Premium'
													: 'Regular'}
											</span>
											<CheckItemText type="small">
												Telegram account
											</CheckItemText>
										</>
									) : (
										<CheckItemText type="small">
											Telegram Premium Check{' '}
										</CheckItemText>
									)}
								</CheckItemText>
								{telePremiumCheck?.info?.reward &&
									telegramPremiumProgress >= 100 && (
										<RewardWrapper>
											<CheckItemText type="smallBold">
												<Reward>
													<span>Reward:</span>
													<Amount>
														<span>
															+
															{formatNumber(
																telePremiumCheck?.info?.reward || 0
															)}
														</span>
														<ImageToken
															src="/images/tokens/patc.svg"
															alt="patc"
														/>{' '}
													</Amount>
												</Reward>
											</CheckItemText>
										</RewardWrapper>
									)}
							</CheckItemContent>
							<ProgressCircleUI percent={telegramPremiumProgress} />
						</CheckItem>
						{/* </>
						) : (
							<>
								<CheckItem>
									<CheckItemText>Account Age Verified</CheckItemText>
									<ProgressCircleUI percent={verifyAgeProgress} />
								</CheckItem>
								<CheckItem>
									<CheckItemText>Telegram Premium Check</CheckItemText>
									<ProgressCircleUI percent={telegramPremiumProgress} />
								</CheckItem>
							</>
						)} */}
					</CheckList>
					{telegramPremiumProgress >= 100 && (
						<ButtonContinue onClick={() => navigate(ROUTES.HOME)}>
							Continue
						</ButtonContinue>
					)}
				</Content>
			</Container>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: 100vw;
	height: 100vh;
	background: #111113;
	overflow-y: auto;
`;
const Layer = styled.div`
	position: absolute;
	top: 0;
	width: 100dvw;
	background: url(/images/checking/bg-earth.webp);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 437px;
`;
const ImageRocket = styled.img`
	width: 100%;
	height: auto;
	flex-shrink: 1;
	min-height: calc(100% - 380px);
	object-fit: contain;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	flex-grow: 1;
`;

const Container = styled.div`
	position: relative;
	z-index: 1;
	padding: 0 16px;
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const Title = styled.div`
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	color: #fff;
	font-family: Poppins;
`;

const CheckList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 100%;
`;

const CheckItem = styled.div`
	display: flex;
	padding: 12px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	border-radius: 8px;
	background: linear-gradient(90deg, #082954 0%, #143b6e 100%);
	transition: all 0.3s ease;
`;

const CheckItemContent = styled.div`
	display: flex;
	flex-direction: column;
	/* gap: 4px 0; */
`;

const CheckItemText = styled.div<{ type?: string }>`
	color: #fff;
	font-family: Poppins;
	display: flex;
	gap: 4px;
	align-items: center;
	font-size: ${(props) => {
		if (props.type === 'small' || props.type === 'smallBold') {
			return '14px';
		}
		if (props.type === 'title') {
			return '20px';
		}
		return '16px';
	}};
	font-style: normal;
	font-weight: ${(props) => {
		if (props.type === 'title' || props.type === 'smallBold') {
			return 600;
		}
		return 500;
	}};
	line-height: auto;
`;

const Reward = styled.div`
	display: flex;
	gap: 4px;
`;
const RewardWrapper = styled.div`
	animation: ${fadeIn} 0.3s ease;
`;
const Amount = styled.div`
	color: #fec424;
	display: flex;
	align-items: center;
	gap: 4px;
`;

const FireIcon = styled.img`
	width: 24px;
	height: 24px;
`;

const ImageToken = styled.img`
	width: 16px;
	height: 16px;
	object-fit: contain;
`;

const ButtonContinue = styled.button`
	display: flex;
	width: 100%;
	max-width: 300px;
	height: 46px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;

	border-radius: 8px;
	border: 1.4px solid #7fc2eb;
	background: linear-gradient(
			319deg,
			#fff -1.9%,
			rgba(255, 255, 255, 0) 52.62%,
			#fff 105%
		),
		#007aff;
	background-blend-mode: soft-light, normal;

	color: var(--Native-text_color, #fff);
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	animation: ${fadeIn} 0.3s ease;
	margin: 0 auto 40px;
`;

export default RequiredMission;
