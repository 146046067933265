import { createSwap } from '@mytonswap/widget';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { fadeIn } from '../constants/css';

const SwapPage = () => {
	const [tc] = useTonConnectUI();
	const initMounted = useRef(false);
	useEffect(() => {
		if (!tc || initMounted.current) return;
		initMounted.current = true;
		if (tc) {
			createSwap('swap-component', {
				tonConnectInstance: tc,
				options: {
					app_id: 'padton_43_51',
				},
			});
		}
	}, [tc]);
	return (
		<Wrapper
			id="swap-component"
			style={{ width: '100%', position: 'relative' }}
		></Wrapper>
	);
};

export default SwapPage;
const Wrapper = styled.div`
	color: #fff;
	display: flex;
	flex-direction: column;
	gap: 20px;
	animation: ${fadeIn} 0.5s ease;
	background-image: url('/images/layout/light.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: center -65px;
	padding-top: 50px;
`;
