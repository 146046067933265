import styled from 'styled-components';
import ButtonUI from '../UI/Button/ButtonUI';

export const CardWrapper = styled.div`
	border-radius: 12px;
	padding: 12px;
	box-sizing: border-box;
	background: linear-gradient(90deg, #082954 0%, #143b6e 100%);
`;

export const CardHeader = styled.div``;

export const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

export const Status = styled.div<{ color: string; backgroundColor: string }>`
	color: ${({ color }) => color};
	background-color: ${({ backgroundColor }) => backgroundColor};
	display: flex;
	height: 27px;
	padding: 8px 12px;
	align-items: center;
	gap: 4px;
	border-radius: 50px;
	font-size: 10px;
	font-weight: 700;
	line-height: 12px;
	box-sizing: border-box;
`;

export const Dot = styled.div<{ color: string }>`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: ${({ color }) => color};
`;

export const Affiliate = styled.div`
	border-radius: 100px;
	background: #007aff;
	display: flex;
	width: 62px;
	padding: 3px 8px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	color: #fff;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 600;
	box-sizing: border-box;
	height: 27px;
`;

export const Subtags = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin: 12px 0;
	height: 20px;
`;

export const SubTagWrapper = styled.div<{ color: string }>`
	display: flex;
	padding: 4px 8px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	height: 20px;
	border-radius: 100px;
	background-color: ${({ color }) => color};
	color: #fff;
	font-family: Poppins;
	font-size: 10px;
	font-weight: 600;
	line-height: 12px;
	box-sizing: border-box;
`;

export const AvatarBlock = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	width: 100%;
	box-sizing: border-box;
`;

export const Avatar = styled.img`
	width: 50px;
	height: 50px;
	object-fit: cover;
	border-radius: 50%;
	object-position: center;
`;

export const NameInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 4px;
	flex: 1;
`;

export const Name = styled.div`
	font-family: Poppins;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	color: #fff;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: calc(100% - 80px);
`;

export const Rate = styled.div`
	color: #b4b4b4;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
`;

export const SoftCap = styled(Row)`
	margin-top: 12px;
`;

export const Label = styled.div`
	color: #b4b4b4;
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
`;

export const SoftCapValue = styled.div`
	color: #00abeb;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
`;

export const LiquidityBlock = styled.div`
	margin: 8px 0;
	padding: 8px 12px;
	box-sizing: border-box;
	border-radius: 8px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	width: 100%;
`;

export const LiquidityValue = styled.div`
	color: #fff;
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
`;

export const OfferedValue = styled(LiquidityValue)``;

export const ProgressLabel = styled(LiquidityValue)`
	line-height: 18px;
`;

export const ProgressWrapper = styled.div`
	margin: 4px 0;
	position: relative;
	width: 100%;
`;

export const Line = styled.div`
	background: #28303f;
	height: 12px;
`;
export const Progress = styled.div<{ width: string | number }>`
	background: linear-gradient(90deg, #2af598 0%, #009efd 100%);
	height: 12px;
	width: ${({ width }) => width}%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
`;

export const BorderRight = styled.div<{ width: string | number }>`
	height: 36px;
	width: ${({ width }) => width}%;
	border-right: 1px solid #009efd;
	background: linear-gradient(
		90deg,
		rgba(0, 158, 253, 0) 24%,
		rgba(0, 158, 253, 0.5) 100%
	);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
`;

export const BottomBlock = styled(Row)`
	margin-top: 12px;
	align-items: flex-end;
`;

export const TimeBlock = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	height: 66px;
`;

export const CountdownBlock = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 4px;
	flex: 1;
`;

export const CountdownItem = styled.div`
	display: flex;
	width: 40px;
	padding: 4px 2px;
	box-sizing: border-box;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2px;
	border-radius: 4px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.2) 0%,
		rgba(0, 0, 0, 0.02) 100%
	);
`;

export const Time = styled.div`
	color: #fff;
	font-family: Digital Numbers;
	font-size: 12px;
	font-weight: 400;
`;

export const Unit = styled.div`
	color: #fff;
	font-family: Poppins;
	font-size: 11px;
	font-weight: 400;
	line-height: 16px;
`;

export const StyledButtonUI = styled(ButtonUI)`
	width: 100px;
	margin: 0;
	height: 44px;
`;
