import React from 'react';
import styled from 'styled-components';

const SectionTitle = ({ text, ...props }: { text: string }) => {
	return (
		<Wrapper {...props}>
			<span>{text}</span>
			<FireIcon src="/gifs/fire.gif" alt="fire" />
		</Wrapper>
	);
};

const Wrapper = styled.div`
	color: var(--Main, var(--Native-text_color, #fff));
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	display: flex;
	align-items: center;
	gap: 4px;
	padding: 0 16px;

	margin-bottom: 12px;
`;

const FireIcon = styled.img`
	width: 24px;
	height: 24px;
	flex-shrink: 0;
`;

export default SectionTitle;
