import { useEffect, useState } from 'react';
import { LoyaltyWalletRequest } from '../service/loyalty-wallet.request';
import { LuckySpinRequest } from '../service/lucky-spin.request';
import { BalanceType, QUERY_KEYS } from '../constants';
import { useQueryClient } from '@tanstack/react-query';
import { getStaleTime } from '../utils/common';

const useGameInfo = () => {
	const queryClient = useQueryClient();
	const loyaltyWalletRequest = new LoyaltyWalletRequest();
	const luckySpinRequest = new LuckySpinRequest();
	const [farmBalance, setFarmBalance] = useState(0);
	const [totalSpin, setTotalSpin] = useState(0);

	useEffect(() => {
		getBalance();
		getTotalSpin();
	}, []);

	const getBalance = async () => {
		try {
			const farmBalance = await queryClient.fetchQuery({
				queryKey: [QUERY_KEYS.GET_BALANCE, BalanceType.FARM],
				queryFn: () => loyaltyWalletRequest.getBalance(BalanceType.FARM),
				staleTime: getStaleTime(15),
			});
			setFarmBalance(parseInt(farmBalance.data.data));
		} catch (error) {
			console.error(error);
		}
	};

	const getTotalSpin = async () => {
		try {
			const res = await queryClient.fetchQuery({
				queryKey: [QUERY_KEYS.GET_TOTAL_SPIN],
				queryFn: () => luckySpinRequest.getProfile(),
				staleTime: getStaleTime(15),
			});

			const totalSpin = res.data.data.totalSpin;
			setTotalSpin(totalSpin);
		} catch (error) {
			console.log(error);
		}
	};

	return { farmBalance, totalSpin };
};

export default useGameInfo;
