import React from 'react';

export default function WalletIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
		>
			<g clipPath="url(#clip0_458_3666)">
				<path
					d="M14.5664 4.61328L16.6523 2.52734C12.8711 -0.839843 7.15234 -0.84375 3.37109 2.52734L5.45703 4.61328C8.07422 2.39063 11.9453 2.39063 14.5664 4.61328Z"
					fill="white"
					fillOpacity="0.3"
				/>
				<path
					d="M17.4844 3.35938L15.3984 5.44531C15.6719 5.76953 15.9141 6.11328 16.1211 6.47266C17.582 9.00781 17.3438 12.2617 15.3984 14.5586L17.4844 16.6445C20.8516 12.8633 20.8555 7.14062 17.4844 3.35938Z"
					fill="white"
					fillOpacity="0.3"
				/>
				<path
					d="M5.45313 15.3867L3.36719 17.4727C7.14844 20.8398 12.8672 20.8438 16.6484 17.4727L14.5625 15.3867C11.9453 17.6094 8.07422 17.6094 5.45313 15.3867Z"
					fill="white"
					fillOpacity="0.3"
				/>
				<path
					d="M3.89844 6.47266C3.91406 6.44922 4.16797 5.98437 4.62109 5.44531L2.53516 3.35938C-0.832031 7.14062 -0.835937 12.8594 2.53516 16.6406L4.62109 14.5547C2.67578 12.2617 2.43359 9.00391 3.89844 6.47266Z"
					fill="white"
					fillOpacity="0.3"
				/>
				<path
					d="M10.8569 14.0618V14.6694C10.8569 14.8531 10.7058 15 10.5169 15H9.48311C9.29422 15 9.1431 14.8531 9.1431 14.6694V14.0618C8.03034 13.7846 7.1889 12.9866 7.02748 12.0083C6.99657 11.8314 7.14425 11.6678 7.32971 11.6678H8.46651C8.59015 11.6678 8.70693 11.7412 8.74814 11.8548C8.88552 12.2154 9.41099 12.5025 9.99828 12.5025C10.6817 12.5025 11.2828 12.1119 11.2828 11.6678C11.2828 11.2237 10.6817 10.8331 9.99828 10.8331C8.34631 10.8331 7 9.71119 7 8.33222C7 7.20367 7.90326 6.24541 9.1431 5.93823V5.33055C9.1431 5.14691 9.29422 5 9.48311 5H10.5169C10.7058 5 10.8569 5.14691 10.8569 5.33055V5.93823C11.9697 6.21536 12.8111 7.01336 12.9725 7.99165C13 8.16861 12.8558 8.33222 12.6703 8.33222H11.5335C11.4098 8.33222 11.2931 8.25876 11.2519 8.14524C11.1145 7.78464 10.589 7.4975 10.0017 7.4975C9.31826 7.4975 8.71723 7.88815 8.71723 8.33222C8.71723 8.77629 9.31826 9.16695 10.0017 9.16695C11.6537 9.16695 13 10.2888 13 11.6678C13 12.7963 12.0933 13.7546 10.8569 14.0618Z"
					fill="white"
					fillOpacity="0.3"
				/>
			</g>
			<defs>
				<clipPath id="clip0_458_3666">
					<rect width={20} height={20} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
