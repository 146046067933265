import styled from 'styled-components';
import { fadeIn } from '../../../constants/css';

export const Wrapper = styled.div`
	color: #fff;
	padding: 20px 16px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	animation: ${fadeIn} 0.5s ease;
	background-image: url('/images/layout/light.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: center -65px;
`;

export const PoolInfo = styled.div`
	width: 100%;
`;

export const DateTime = styled.div`
	color: #fff;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 600;
	text-align: center;
`;

export const CenterBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 12px;
	margin-top: 16px;
`;

export const UserAvatarWrapper = styled.div`
	width: 80px;
	height: 80px;
	position: relative;
`;

export const Avatar = styled.img`
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
	object-position: center;
	border: 1px solid #fff;
`;

export const Crown = styled.img`
	position: absolute;
	top: 0;
	right: 0;
	width: 28px;
	height: 28px;
	object-fit: contain;
`;

export const CenterRightBlock = styled.div`
	display: flex;
	flex-direction: column;
	gap: 6px;
	justify-content: center;
`;
export const UserName = styled.div`
	color: #fff;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
`;
export const WinnerTicket = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
`;

export const CircleResult = styled.div`
	color: #fec424;
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	border-radius: 100px;
	border: 1px solid #fec424;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Prize = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	color: #fff;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
`;

export const PrizeNumber = styled.span`
	font-weight: 500;
`;
export const TokenImage = styled.img`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	object-fit: cover;
`;
export const CheckingResultBlock = styled.div`
	display: flex;
	padding: 10px 0px;
	flex-direction: column;
	align-items: center;
	align-self: stretch;
	border-radius: 10px;
	background: #0e213a;
	margin-top: 10px;
`;
export const Label = styled.div`
	color: #fff;
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
`;

export const ResultLink = styled.a`
	text-decoration: none;
	color: #fec424;
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
`;
export const AllParticipantsBlock = styled.div``;
export const Title = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 4px;
	color: #fff;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 600;
`;

export const FireGif = styled.img`
	width: 24px;
	height: 24px;
	object-fit: contain;
`;

export const Participants = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	background: linear-gradient(90deg, #082954 0%, #143b6e 100%);
	padding: 2px 0;
	margin-top: 12px;
`;

export const ParticipantItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 16px;
	gap: 16px;
	position: relative;
`;

export const ParticipantLeftBlock = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	flex: 1;
`;

export const ParticipantAvatar = styled.img`
	width: 35px;
	height: 35px;
	object-fit: cover;
	object-position: center;
	border-radius: 50%;
	border: 1px solid #fec424;
`;

export const ParticipantInfo = styled.div``;

export const ParticipantName = styled.div`
	color: #fff;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
`;

export const ParticipatedAt = styled.div`
	color: rgba(255, 255, 255, 0.7);
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
`;

export const ParticipantTicket = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	justify-content: center;
`;

export const TicketNumber = styled.div`
	color: #fff;
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	border-radius: 100px;
	border: 1px solid #fff;
	width: 26px;
	height: 26px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const Divider = styled.div`
	height: 1px;
	width: 80%;
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.2) 50%,
		rgba(255, 255, 255, 0) 100%
	);
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
`;
export const LoadingWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
`;
export const Loader = styled.div``;
