import React from 'react';

const CheckedProgressIc = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={30}
			height={30}
			viewBox="0 0 30 30"
			fill="none"
		>
			<path
				d="M13.4539 16.3187C13.3756 16.3907 13.2501 16.4072 13.1499 16.3598L8.59773 14.2003C8.52923 14.1677 8.46298 14.154 8.40147 14.154C8.27922 14.154 8.17572 14.208 8.10771 14.2744C8.00521 14.3745 7.93796 14.5521 8.08571 14.7236L13.2971 20.7674C13.4336 20.9255 13.6431 21.0102 13.8671 20.999C14.0939 20.9879 14.2904 20.8835 14.4057 20.7123L21.9369 9.539C22.0749 9.33436 21.9631 9.15993 21.8259 9.07272C21.6889 8.98594 21.4786 8.95144 21.2999 9.11536L13.4539 16.3187Z"
				fill="#15B643"
			/>
		</svg>
	);
};

export default CheckedProgressIc;
