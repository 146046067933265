import styled from 'styled-components';
import { useAppSelector } from '../../redux/store';
import ArrowSquareLeftIc from '../Icons/ArrowSquareLeftIc';
import { formatNumber } from '../../utils/common';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import ConnectWallet from '../UI/ConnectWallet';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { useEffect } from 'react';

const Balance = () => {
	const { isIntersecting, ref } = useIntersectionObserver({
		threshold: 0.2,
		initialIsIntersecting: true,
	});

	const navigate = useNavigate();
	const balance = useAppSelector((state) => state.loyaltyWallet.balance);

	useEffect(() => {
		const balanceHeader = document.getElementById('user-balance');
		if (balanceHeader) {
			if (!isIntersecting) {
				balanceHeader.style.opacity = '1';
			} else {
				balanceHeader.style.opacity = '0';
			}
			return () => {
				balanceHeader.style.opacity = '1';
			};
		}
	}, [isIntersecting]);

	return (
		<>
			<Container ref={ref}>
				<Note>Total rewards balance</Note>
				<Amount>
					<TokenIcon src="/images/tokens/patc.svg" alt="patc" />{' '}
					<AmountValue>
						<span>{formatNumber(balance)}</span>
						<ArrowSquareLeftIc onClick={() => navigate(ROUTES.WALLET)} />
					</AmountValue>
				</Amount>

				<ConnectWallet isShowBalance isShowTitle />
			</Container>
		</>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 12px;
`;

const Note = styled.div`
	color: var(--Native-text_color, #fff);
	text-align: center;
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 183.333% */
`;

const Amount = styled.div`
	color: var(--Native-text_color, #fff);
	font-family: Poppins;
	font-size: 30px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 8px;
`;

const AmountValue = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`;

const TokenIcon = styled.img`
	width: 32px;
	height: 32px;
`;

export default Balance;
