import BigNumber from 'bignumber.js';
import { LAUNCHPAD_STATUS, POOL_STATUS, SubTag } from '../constants/launchpad';

export const getStatusColor = (status: string) => {
	switch (status) {
		case LAUNCHPAD_STATUS.WAITING_TO_REVIEW:
			return '#71EDE6';
		case LAUNCHPAD_STATUS.UPCOMING:
			return '#FFE500';
		case LAUNCHPAD_STATUS.ACTIVE:
			return '#43D26C';
		case LAUNCHPAD_STATUS.LISTING_END:
			return '#9747FF';
		case LAUNCHPAD_STATUS.SALE_ENDED:
			return '#999696';
		case LAUNCHPAD_STATUS.CANCELED:
			return '#EB0000';
		case LAUNCHPAD_STATUS.FINISHED:
			return '#ff9900';
		case LAUNCHPAD_STATUS.KYC:
			return '#00A3FF';
		case LAUNCHPAD_STATUS.WAITING_FOR_KYC:
			return '#00A3FF';
		case LAUNCHPAD_STATUS.LISTED:
			return '#00FFD1';
		case LAUNCHPAD_STATUS.REJECTED:
			return '#FFFFFF';
		default:
			return '#fff';
	}
};

export const getStatusTag = (
	status: number,
	startTime: number,
	endTime: number,
	lpAddress?: string | null
) => {
	if (status === POOL_STATUS.APPROVED) {
		if (new Date().valueOf() < startTime) {
			return LAUNCHPAD_STATUS.UPCOMING;
		}
		if (new Date().valueOf() >= startTime && new Date().valueOf() < endTime) {
			return LAUNCHPAD_STATUS.ACTIVE;
		}
		return LAUNCHPAD_STATUS.SALE_ENDED;
	}
	if (!!lpAddress) {
		return LAUNCHPAD_STATUS.LISTED;
	}
	if (status === POOL_STATUS.REJECTED) {
		return LAUNCHPAD_STATUS.REJECTED;
	}
	if (status === POOL_STATUS.FINISHED) {
		return LAUNCHPAD_STATUS.FINISHED;
	}
	if (status === POOL_STATUS.CANCELLED) {
		return LAUNCHPAD_STATUS.CANCELED;
	}
	return LAUNCHPAD_STATUS.WAITING_TO_REVIEW;
};

export const getSubtagColor = (tag: string) => {
	switch (tag) {
		case SubTag.WAITING_TO_KYC:
			return '#4666FF';
		case SubTag.REFUNDABLE:
			return '#E42FF3';
		case SubTag.AMA:
			return '#22C55E';
		case SubTag.KYC:
			return '#4666FF';
		case SubTag.AUDIT:
			return '#0EA5E9';
		default:
			return '#4666FF';
	}
};

export const normalizeDataForProjectCard = (pool: PoolContent) => {
	return {
		id: pool.id,
		address: pool.address,
		allowEarlyRefund: pool.allowEarlyRefund,
		projectName: pool.info.metaData.name,
		image: pool.info.logo,
		startTime: Number(pool.startTime) * 1000,
		endTime: Number(pool.endTime) * 1000,
		listingTime: Number(pool.listingTime) * 1000,
		price: {
			price:
				new Date().valueOf() < Number(pool.endTime) * 1000
					? new BigNumber(pool.softCap).div(pool.totalSellToken).toNumber()
					: new BigNumber(pool.totalSoldToken)
							.div('1e9')
							.div(pool.totalSellToken)
							.toNumber(),
			currency: 'TON',
		},
		softCap: Number(pool.softCap),
		liquidity: Number(pool.liquidityFeePercent) / 10,
		offered: new BigNumber(pool.totalSellToken).toNumber(),
		soldToken: new BigNumber(pool.totalSoldToken).div('1e9').toNumber(),
		affiliate: Number(pool.affiliateFeePercent) / 10,
		symbol: pool.info.metaData.symbol,
		status: Number(pool.status),
		kycStatus: pool.kycStatus,
		lpAddress: pool.lpAddress,
	};
};
