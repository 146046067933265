import { Button } from 'antd';
import styled from 'styled-components';
export const LoadingWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 164px);
`;

export const Wrapper = styled.div<{ isPaddingBottom?: boolean }>`
	position: relative;
	padding: 0 16px ${(props) => (props.isPaddingBottom ? '100px' : '16px')};
`;

export const Banner = styled.img`
	width: 100%;
	height: auto;
	object-fit: contain;
	border-radius: 10px;
`;

export const Info = styled.div`
	margin-top: 12px;
`;

export const Name = styled.div`
	color: #fff;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 600;
	line-height: 26px;
`;

export const Description = styled.div`
	color: #fff;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
`;
export const CampaignData = styled.div`
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	margin: 8px 0;
`;

export const CampaignTime = styled.div`
	display: flex;
	align-items: center;
	gap: 2px;

	color: #fff;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 500;
	line-height: 26px;
`;
export const ClockIcon = styled.img`
	width: 20px;
	height: 20px;
	object-fit: contain;
`;

export const Participants = styled(CampaignTime)`
	justify-content: flex-end;
	text-align: right;
`;
export const GroupIcon = styled(ClockIcon)``;

export const RegisterBtn = styled(Button)`
	width: 100%;
	font-size: 16px;
	font-weight: 600;
	padding: 16px 20px;
	height: 45px;
	margin-top: 12px;
	border-radius: 10px;
`;

export const ViewDetailBtn = styled(RegisterBtn)`
	background: transparent;
	border-radius: 10px;
	border: 1px solid #00abeb;
	color: #00abeb;

	max-width: 306px;
`;

export const InfoFooter = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
export const Prize = styled.div`
	display: flex;
	gap: 6px;
	align-items: center;

	color: #fff;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 500;
	line-height: 26px;
	margin: 8px 0;
`;

export const Challenge = styled(Prize)`
	align-items: flex-start !important;
`;

export const CountdownSection = styled.div``;

export const Title = styled(Name)`
	font-size: 14px;
	text-align: center;
	margin-top: 12px;
`;

export const CountdownWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	margin-top: 8px;
`;

export const TimeBlock = styled.div`
	display: flex;
	padding: 6px 2px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2px;
	background: linear-gradient(90deg, #082954 0%, #143b6e 100%);
	border: 0.345px solid #fff;
	border-radius: 4px;

	color: #fff;
	font-family: Poppins;
	font-size: 12px;
	line-height: 16px;
`;
export const TimeNumber = styled.div`
	font-family: 'Digital Numbers';
	color: #fff;
`;

export const UserRanking = styled.div`
	border-radius: 8px;
	border: 1.4px dashed #7fc2eb;
	background: linear-gradient(
			319deg,
			#fff -1.9%,
			rgba(255, 255, 255, 0) 52.62%,
			#fff 105%
		),
		#02143c;
	background-blend-mode: soft-light, normal;
	box-shadow: 0px 2px 2px 0px #02143c, 0px 0px 16px 0px rgba(2, 20, 60, 0.32);

	display: flex;
	padding: 12px 16px;
	align-items: center;
	justify-content: space-between;
	margin-top: 12px;
`;

export const LeftBlock = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const UserAvatar = styled.img`
	width: 38px;
	height: 38px;
	border-radius: 50%;
	overflow: hidden;
`;

export const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
`;
export const UserName = styled.div`
	color: #fff;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
`;

export const FriendNumber = styled(UserName)`
	font-size: 12px;
	font-weight: 400;
`;

export const RightBlock = styled.div`
	color: #fec424;
	text-align: right;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px; /* 150% */

	display: flex;
	align-items: center;
	gap: 4px;
`;

export const RankingList = styled.div`
	margin-top: 12px;
	border-radius: 10px;
	background: linear-gradient(90deg, #082954 0%, #143b6e 100%);
`;

export const User = styled.div`
	border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
	display: flex;
	padding: 12px 16px;
	align-items: center;
	justify-content: space-between;
	&:last-child {
		border-bottom: none;
	}
`;

export const PopupContent = styled.div`
	max-height: 60vh;
	overflow-y: auto;
	overflow-x: hidden;
`;

export const MissionList = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

export const MissionItem = styled.div`
	padding: 12px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	border-radius: 10px;
	background: var(
		--Button-Linear,
		linear-gradient(90deg, #082954 0%, #143b6e 100%)
	);
	gap: 16px;
`;
export const MissionItemIcon = styled.div`
	border-radius: 8px;
	display: flex;
	width: fit-content;
	height: fit-content;
	justify-content: center;
	align-items: center;
	gap: 6px;
	width: 30px;
	height: 30px;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

export const MissionItemContent = styled.div`
	display: flex;
	gap: 8px;
	color: #fff;
	font-family: Poppins;
	font-size: 14px;
	align-items: center;
`;

export const MissionToken = styled.img`
	width: 16px;
	height: 16px;
`;

export const MissionText = styled.div``;
export const MissionTitle = styled.div`
	color: #fff;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px; /* 185.714% */
`;
export const MissionAmount = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	line-height: 22px;
	font-weight: 400;
`;
export const MissionButton = styled(Button)`
	cursor: pointer;
	display: flex;
	padding: 8px 20px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border: none;
	border-radius: 6px;
	background: #007aff;
	height: 30px;
	box-sizing: border-box;

	color: #fff;
	text-align: center;

	font-family: Poppins;
	font-size: 14px;
	font-weight: 600;
	line-height: 18px;
`;

export const Footer = styled.div`
	margin: 12px 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ButtonJoin = styled.div<{ $disable?: boolean }>`
	cursor: pointer;
	display: flex;
	max-width: 306px;
	width: 100%;
	margin: 0 auto;
	padding: 10px 0;
	justify-content: center;
	align-items: center;

	border-radius: 8px;

	${(props) => {
		if (props.$disable) {
			return {
				border: '1.4px solid #767677',
				background:
					'linear-gradient(319deg,#fff -1.9%,rgba(255, 255, 255, 0) 52.62%,#fff 105%),#464b51',
				backgroundBlendMode: 'soft-light, normal',
				color: 'rgba(255, 255, 255, 0.5)',
			};
		}

		return {
			border: '1.4px solid #7FC2EB',
			background:
				'linear-gradient(319deg, #FFF -1.9%, rgba(255, 255, 255, 0.00) 52.62%, #FFF 105%), #007AFF',
			backgroundBlendMode: 'soft-light, normal',
			color: '#fff',
		};
	}}

	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	pointer-events: ${(props) => (props.$disable ? 'none' : 'auto')};
`;

export const HeaderTopRanking = styled.div`
	margin-top: 12px;
	display: flex;
	justify-content: center;
`;

export const RankingToken = styled.img`
	width: 18px;
	height: 18px;
`;

export const LayerBlur = styled.div`
	position: absolute;
	top: 0;
	left: -240px;
	z-index: -1;

	width: 326px;
	height: 327px;
	flex-shrink: 0;

	border-radius: 327px;
	background: #0084ff;
	filter: blur(100px);
`;

export const CardTag = styled.div<{ $type?: 'live' | 'finished' }>`
	position: absolute;
	top: 16px;
	left: 16px;
	display: flex;
	padding: 8px 12px;
	align-items: center;
	gap: 4px;

	border-radius: 50px;
	background: ${(props) => (props.$type === 'live' ? '#15b643' : '#EB0000')};

	color: #fff;
	/* font-family: 'Plus Jakarta Sans'; */
	font-size: 10px;
	font-style: normal;
	font-weight: 700;
	line-height: 12px; /* 120% */
	width: fit-content;
	text-transform: uppercase;
`;

export const CardBg = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	flex-shrink: 0;
`;

export const BannerWrapper = styled.div`
	position: relative;
`;

export const RankNumber = styled.div`
	color: var(--Native-text_color, #fff);
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	width: 38px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const RankingWrapper = styled.div`
	position: relative;
`;

export const WrapperClaim = styled.div`
	position: fixed;
	bottom: 0;
	height: 80px;
	width: 100%;
	background: linear-gradient(90deg, #082954 0%, #143b6e 100%);
`;

export const ButtonClaim = styled(ButtonJoin)`
	margin-top: 12px;
	display: flex;
	gap: 8px;
`;

export const CongratulationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
`;
export const CongratulationBg = styled.img`
	width: 318px;
	height: 187px;
	object-fit: contain;
`;

export const CongratulationReward = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;

	color: #f5d245;

	/* App/Header/3 */
	font-family: Poppins;
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px; /* 154.545% */
`;
export const CongratulationToken = styled.img`
	width: 30px;
	height: 30px;
`;

export const CongratulationText = styled.div`
	color: var(--Native-text_color, #fff);
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 128.571% */
`;

export const CongratulationButton = styled.button`
	border-radius: 10px;
	background: var(--Web-Primary, #00abeb);
	border: none;

	display: flex;
	padding: 12px 20px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	align-self: stretch;

	color: var(--Main, var(--Native-text_color, #fff));
	text-align: center;

	/* App/Button/Large */
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px; /* 131.25% */

	cursor: pointer;
`;

export const TaskList = styled.div`
	color: #fff;
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 600;
	margin-top: 10px;
`;
export const TaskListDesc = styled.div`
	color: #fff;
	text-align: center;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
`;

export const Status = styled.div<{
	status?: 'unjoined' | 'joined' | 'completed';
}>`
	text-align: right;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 600;
	line-height: 26px;
	text-transform: uppercase;
	color: ${(props) => {
		switch (props.status) {
			case 'unjoined':
				return '#fff';
			case 'joined':
				return '#00ABEB';
			case 'completed':
				return '#15B643';
			default:
				return '#fff';
		}
	}};
`;
