import React from 'react';

const ClockIc = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M10 5.09998C9.03088 5.09998 8.08351 5.38736 7.27771 5.92577C6.47191 6.46419 5.84387 7.22947 5.473 8.12483C5.10213 9.02018 5.00509 10.0054 5.19416 10.9559C5.38323 11.9064 5.84991 12.7795 6.53518 13.4648C7.22046 14.1501 8.09356 14.6168 9.04406 14.8058C9.99457 14.9949 10.9798 14.8979 11.8752 14.527C12.7705 14.1561 13.5358 13.5281 14.0742 12.7223C14.6126 11.9165 14.9 10.9691 14.9 9.99997C14.8986 8.70085 14.3819 7.45534 13.4633 6.53672C12.5446 5.6181 11.2991 5.10139 10 5.09998ZM12.2307 11.6893C12.1872 11.7443 12.132 11.7889 12.0689 11.8196C12.0059 11.8503 11.9368 11.8664 11.8667 11.8666C11.7611 11.8675 11.6587 11.8311 11.5773 11.764L9.71062 10.364C9.65565 10.3205 9.61117 10.2652 9.58048 10.2022C9.5498 10.1392 9.53368 10.0701 9.53334 9.99997V6.73331C9.53334 6.60954 9.58251 6.49084 9.67002 6.40333C9.75754 6.31581 9.87624 6.26664 10 6.26664C10.1238 6.26664 10.2425 6.31581 10.33 6.40333C10.4175 6.49084 10.4667 6.60954 10.4667 6.73331V9.77597L12.1561 11.036C12.2046 11.0734 12.2452 11.1202 12.2753 11.1736C12.3055 11.227 12.3246 11.2859 12.3316 11.3469C12.3385 11.4078 12.3332 11.4695 12.3159 11.5283C12.2985 11.5871 12.2696 11.6419 12.2307 11.6893Z"
				fill="white"
			/>
			<path
				d="M10 3C8.61553 3 7.26215 3.41054 6.11101 4.17971C4.95987 4.94888 4.06266 6.04213 3.53285 7.32122C3.00303 8.6003 2.86441 10.0078 3.13451 11.3656C3.4046 12.7235 4.07129 13.9708 5.05026 14.9497C6.02922 15.9287 7.2765 16.5954 8.63437 16.8655C9.99224 17.1356 11.3997 16.997 12.6788 16.4672C13.9579 15.9373 15.0511 15.0401 15.8203 13.889C16.5895 12.7378 17 11.3845 17 10C16.9977 8.14418 16.2595 6.36501 14.9473 5.05275C13.635 3.74048 11.8558 3.00226 10 3ZM10 15.8644C8.84013 15.8644 7.7063 15.5205 6.7419 14.8761C5.77749 14.2317 5.02584 13.3158 4.58197 12.2442C4.1381 11.1726 4.02197 9.99349 4.24825 8.8559C4.47453 7.71832 5.03306 6.67337 5.85322 5.85322C6.67338 5.03306 7.71832 4.47453 8.85591 4.24825C9.9935 4.02197 11.1726 4.1381 12.2442 4.58197C13.3158 5.02583 14.2317 5.77749 14.8761 6.74189C15.5205 7.70629 15.8644 8.84012 15.8644 10C15.8629 11.5549 15.2446 13.0457 14.1451 14.1451C13.0457 15.2446 11.5549 15.8629 10 15.8644Z"
				fill="white"
			/>
		</svg>
	);
};

export default ClockIc;
