import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SectionTitle from '../UI/SectionTitle';
import Slider, { Settings } from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { CampaignRequest } from '../../service/campaign.request';
import CampaignCard from '../UI/CampaignCard';
import { QUERY_KEYS } from '../../constants';
import { useQueryClient } from '@tanstack/react-query';
import { getStaleTime } from '../../utils/common';

const Campaign = () => {
	const queryClient = useQueryClient();
	const listSlide = Array.from({ length: 3 }, (_, i) => i);
	const sliderRef = useRef<Slider>(null);
	const [slideActive, setSlideActive] = useState(0);
	const navigate = useNavigate();
	const campaignRequest = new CampaignRequest();
	const [campaigns, setCampaigns] = useState<Campaign[]>([]);
	const [loading, setLoading] = useState(false);

	const settings: Settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		beforeChange(pre, cur) {
			setSlideActive(cur);
		},
	};

	const getCampaigns = async () => {
		try {
			setLoading(true);
			const res = await queryClient.fetchQuery({
				queryKey: [
					QUERY_KEYS.GET_CAMPAIGNS,
					{
						pageSize: 3,
						order: 'endAt desc',
					},
				],
				queryFn: () =>
					campaignRequest.getAll({
						pageSize: 3,
						order: 'endAt desc',
					}),
				staleTime: getStaleTime(15),
			});
			setCampaigns(res.data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getCampaigns();
	}, []);

	return (
		<Wrapper>
			<Header>
				<SectionTitle text="Campaign" />
				<ViewAllLink onClick={() => navigate(ROUTES.CAMPAIGNS)}>
					View All
				</ViewAllLink>
			</Header>

			{campaigns.length > 1 ? (
				<>
					<Slider ref={sliderRef} {...settings}>
						{campaigns.map((campaign) => (
							<CampaignCard campaign={campaign} key={campaign.id} />
						))}
					</Slider>
					<DotList>
						{listSlide.map((i) => (
							<DotItem key={i} $active={slideActive === i} />
						))}
					</DotList>
				</>
			) : loading ? (
				<CampaignCard loading />
			) : (
				campaigns.map((campaign) => (
					<CampaignCard campaign={campaign} key={campaign.id} />
				))
			)}
		</Wrapper>
	);
};

const Wrapper = styled.div``;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;

const ViewAllLink = styled.div`
	color: #d5d5d5;
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px; /* 175% */
	padding: 0 16px;
`;

const DotList = styled.div`
	margin-top: 12px;
	display: flex;
	gap: 4px;
	justify-content: center;
`;

const DotItem = styled.div<{ $active?: boolean }>`
	width: ${(props) => (props.$active ? '28px' : '5px')};
	height: 5px;

	border-radius: 100px;
	background: ${(props) => (props.$active ? '#007AFF' : '#717989')};
`;

export default Campaign;
