import React from 'react';

export default function FriendActiveIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M1.93573 15.2789C1.95713 14.886 2.01429 14.5003 2.1 14.1289C2.12141 14.0432 2.13571 13.9574 2.16431 13.8717C1.74286 13.7932 1.42857 13.4217 1.42857 12.9789C1.42857 11.8217 1.87857 10.736 2.69287 9.92176C2.8857 9.72174 3.11427 9.53602 3.38571 9.35745C3.71429 9.13602 3.8 8.69317 3.57858 8.36458C3.35715 8.03603 2.9143 7.95032 2.58571 8.17175C2.23572 8.40743 1.94284 8.65031 1.67855 8.90744C0.592869 10.0003 0 11.4431 0 12.9789C0 14.1289 0.835702 15.0932 1.93573 15.2789Z"
				fill="white"
			/>
			<path
				d="M2.52142 4.7146C2.52142 6.29318 3.66426 7.61459 5.16424 7.88603C4.97852 7.36461 4.88571 6.8146 4.88571 6.23603C4.88571 4.40746 5.84997 2.79317 7.3071 1.89316C6.84284 1.63603 6.30713 1.49316 5.74996 1.49316C3.9714 1.49316 2.52142 2.93603 2.52142 4.7146Z"
				fill="white"
			/>
			<path
				d="M17.4785 4.7146C17.4785 2.93603 16.0285 1.49316 14.2499 1.49316C13.6928 1.49316 13.1571 1.63603 12.6928 1.89316C14.1499 2.79317 15.1142 4.40746 15.1142 6.23603C15.1142 6.8146 15.0214 7.36461 14.8357 7.88603C16.3356 7.61459 17.4785 6.29318 17.4785 4.7146Z"
				fill="white"
			/>
			<path
				d="M17.4142 8.17175C17.0857 7.95032 16.6428 8.03603 16.4214 8.36458C16.2 8.69317 16.2857 9.13602 16.6143 9.35745C16.8857 9.53602 17.1143 9.72174 17.3071 9.91461C18.1214 10.736 18.5714 11.8217 18.5714 12.9789C18.5714 13.2218 18.4785 13.4503 18.2928 13.6289C18.1785 13.7503 18.0142 13.836 17.8428 13.8717C17.9643 14.3289 18.0357 14.8003 18.0714 15.2789C18.5428 15.2003 18.9785 14.9717 19.3142 14.636C19.7571 14.186 20 13.6003 20 12.9789C20 11.4431 19.4071 10.0003 18.3214 8.90744C18.0571 8.65031 17.7642 8.40743 17.4142 8.17175Z"
				fill="white"
			/>
			<path
				d="M8.3391 9.50218C10.6484 10.7908 13.7361 8.91864 13.6824 6.23409C13.6824 4.20354 12.0306 2.55176 10.0001 2.55176C7.96953 2.55176 6.31775 4.20354 6.31775 6.23409C6.31775 7.66406 7.1453 8.89248 8.3391 9.50218Z"
				fill="white"
			/>
			<path
				d="M13.6519 10.2936C13.3898 10.1455 13.0473 10.1466 12.8086 10.3438C11.2335 11.6524 8.76702 11.6524 7.19196 10.3438C6.95323 10.147 6.61065 10.1454 6.34863 10.2936C5.97056 10.537 5.61412 10.8265 5.28836 11.1529C4.37807 12.0618 3.75586 13.2051 3.49009 14.4488C3.38756 14.8966 3.33594 15.3731 3.33594 15.8648C3.33594 17.322 4.52176 18.5078 5.97893 18.5078H14.0216C14.7282 18.5078 15.3923 18.233 15.891 17.7343C16.3898 17.2355 16.6646 16.5714 16.6646 15.8648C16.6646 14.0938 15.972 12.4204 14.7129 11.1536C14.3864 10.8265 14.03 10.537 13.6519 10.2936Z"
				fill="white"
			/>
		</svg>
	);
}
