import { Button, ButtonProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface IProps extends ButtonProps {
	width?: string;
	height?: string;
	fontSize?: string;
}

const ButtonUI: React.FC<IProps> = ({ ...props }) => {
	return <StyledButton {...props} />;
};

export default ButtonUI;

const StyledButton = styled(Button)<IProps>`
	width: ${(props) => (props.width ? props.width : 'auto')};
	height: ${(props) => (props.height ? props.height : '40px')};
	border-radius: 8px;
	border: 1.4px solid #7fc2eb;
	background: linear-gradient(
			319deg,
			#fff -1.9%,
			rgba(255, 255, 255, 0) 52.62%,
			#fff 105%
		),
		#007aff;
	background-blend-mode: soft-light, normal;
	/* box-shadow: 0px 2px 2px 0px #007aff, 0px 0px 16px 0px rgba(0, 122, 255, 0.32); */
	margin: auto;
	span {
		color: #fff;
		font-family: Poppins;
		font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
		font-weight: 600;
	}
	&:disabled {
		border-radius: 8px;
		border: 1.4px solid #767677;
		background: linear-gradient(
				319deg,
				#fff -1.9%,
				rgba(255, 255, 255, 0) 52.62%,
				#fff 105%
			),
			#464b51;
		background-blend-mode: soft-light, normal;
		box-shadow: 0px 2px 2px 0px #323232, 0px 0px 16px 0px rgba(58, 62, 67, 0.32);

		span {
			color: rgba(255, 255, 255, 0.5);
		}
	}
`;
