import styled from 'styled-components';
import { Button } from 'antd';
import { formatToken } from '../../utils/common';
import PopupUI from './Popup/PopupUI';
import { useAppSelector } from '../../redux/store';
import { useMemo } from 'react';

interface CongratulationModalProps {
	open: boolean;
	onClose: () => void;
	number: number;
	tokenSymbol: string;
	onConfirm: () => void;
	confirmText: string;
}
const ConfirmPaticipationModal = ({
	open,
	onClose,
	number,
	tokenSymbol,
	confirmText,
	onConfirm,
}: CongratulationModalProps) => {
	const currencies = useAppSelector((state) => state.wallet.currencies);

	const currencyImage = useMemo(() => {
		return currencies.find((currency) => currency.symbol === tokenSymbol)?.logo;
	}, [currencies, tokenSymbol]);

	return (
		<PopupUI open={open} onClose={onClose} title="Confirm participation">
			<Wrapper>
				<StyledImage
					src={
						tokenSymbol === 'PATC' ? `/images/tokens/patc.svg` : currencyImage
					}
					alt={tokenSymbol}
				/>
				<Content>
					<>
						<Detail>
							-{formatToken(number || 0, 10)} {tokenSymbol}
						</Detail>
						<StyledDescription>
							{confirmText} {formatToken(number || 0, 10)} {tokenSymbol}
						</StyledDescription>
					</>
					<Action>
						<CloseBtn onClick={onClose}>Close</CloseBtn>
						<ContinueBtn
							onClick={() => {
								onConfirm();
								onClose();
							}}
						>
							Confirm
						</ContinueBtn>
					</Action>
				</Content>
			</Wrapper>
		</PopupUI>
	);
};

export default ConfirmPaticipationModal;

const ContinueBtn = styled(Button)`
	width: 100%;
	border-radius: 10px;
	background: #00abeb;
	height: 45px;

	color: #fff;
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 600;
	line-height: 21px;
`;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
const StyledImage = styled.img`
	width: 100px;
	height: 100px;
	object-fit: contain;
	margin-bottom: 6px;
`;

const Content = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 8px;
	width: 100%;
`;

const Action = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 16px;
	margin-top: 16px;
`;
const Detail = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	color: #f5d245;
	font-size: 22px;
	font-weight: 600;
	line-height: 34px;
`;

const StyledDescription = styled.div`
	color: #fff;
	text-align: center;
	font-size: 14px;
	line-height: 18px;
`;

const CloseBtn = styled(Button)`
	display: flex;
	width: 167px;
	height: 45px;
	padding: 12px 20px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	box-sizing: border-box;
	border-radius: 10px;
	border: 1px solid #00abeb;

	color: #00abeb;
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	background: transparent;
`;
