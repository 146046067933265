const SearchIcon = () => {
	return (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.5982 20.308L17.0747 16.7845C18.0795 15.629 18.5964 14.1286 18.5164 12.5994C18.4364 11.0702 17.7659 9.63185 16.6461 8.58745C15.5262 7.54305 14.0447 6.97423 12.5137 7.0009C10.9826 7.02756 9.52177 7.64763 8.43901 8.73039C7.35625 9.81315 6.73618 11.274 6.70952 12.805C6.68285 14.3361 7.25167 15.8176 8.29607 16.9374C9.34047 18.0572 10.7789 18.7278 12.308 18.8078C13.8372 18.8877 15.3377 18.3709 16.4932 17.3661L20.0167 20.8896C20.0947 20.9622 20.1978 21.0018 20.3044 20.9999C20.411 20.9981 20.5127 20.9549 20.5881 20.8795C20.6635 20.8041 20.7067 20.7024 20.7086 20.5958C20.7104 20.4892 20.6709 20.386 20.5982 20.308ZM7.55208 12.9182C7.55208 11.9145 7.84971 10.9333 8.40733 10.0988C8.96494 9.26428 9.7575 8.61385 10.6848 8.22975C11.6121 7.84566 12.6324 7.74517 13.6168 7.94097C14.6012 8.13678 15.5054 8.6201 16.2151 9.32981C16.9249 10.0395 17.4082 10.9437 17.604 11.9281C17.7998 12.9125 17.6993 13.9329 17.3152 14.8602C16.9311 15.7875 16.2807 16.58 15.4461 17.1376C14.6116 17.6952 13.6305 17.9929 12.6268 17.9929C11.2814 17.9912 9.99157 17.4561 9.04023 16.5047C8.0889 15.5534 7.55372 14.2636 7.55208 12.9182Z"
				fill="#B4B4B4"
			/>
		</svg>
	);
};

export default SearchIcon;
