import React from 'react';

export default function RankingIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M15.3 17.7004V18.0004C15.3 18.5004 14.8 19.0004 14.1 19.0004H5.80001C5.20001 19.0004 4.60001 18.6004 4.60001 18.0004V17.6004C4.60001 16.4004 5.70001 15.5004 7.10001 15.5004H7.30001V14.4004C8.10001 14.7004 9.00001 14.8004 9.80001 14.8004C10.7 14.8004 11.5 14.6004 12.3 14.4004V15.5004H12.5C14.2 15.5004 15.3 16.5004 15.3 17.7004Z"
				fill="white"
				fillOpacity="0.3"
			/>
			<path
				d="M19 4.5C19 3.6 18.3 2.9 17.4 2.9H16.2V2.6C16.2 1.7 15.5 1 14.6 1H5.4C4.5 1 3.8 1.7 3.8 2.6V2.8H2.6C1.7 2.8 1 3.6 1 4.5V6C1 8.2 2.3 10.1 4.3 10.9L5.2 11.3C6.3 12.7 8.1 13.5 10 13.5C11.9 13.5 13.6 12.6 14.8 11.3L15.7 10.9C17.7 10.1 19 8.2 19 6V4.5ZM4.1 9.2C3 8.5 2.4 7.3 2.4 5.9V4.5C2.4 4.4 2.5 4.3 2.6 4.3H3.8V7.3C3.8 7.9 3.9 8.6 4.1 9.2ZM12.5 6.9L11.6 7.7L11.8 8.9C11.9 9.4 11.4 9.7 11 9.5L10 8.9L8.9 9.5C8.5 9.7 8.1 9.3 8.1 8.9L8.3 7.7L7.5 6.9C7.1 6.5 7.3 6 7.8 5.9L9 5.7L9.5 4.6C9.7 4.2 10.3 4.2 10.5 4.6L11 5.7L12.2 5.9C12.7 6 12.9 6.5 12.5 6.9ZM17.6 5.9C17.6 7.2 16.9 8.4 15.9 9.1C16.1 8.5 16.2 7.8 16.2 7.2V4.2H17.4C17.5 4.2 17.6 4.3 17.6 4.4V5.9Z"
				fill="white"
				fillOpacity="0.3"
			/>
		</svg>
	);
}
