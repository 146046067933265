import {
	call,
	CallEffect,
	put,
	PutEffect,
	takeLatest,
} from 'redux-saga/effects';
import walletRequest from '../../service/wallet.request';
import { getWalletReq } from '../actions/wallet.action';
import { setLoading, setWallets } from '../reducers/wallet.slice';
import { AnyAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

function* getWalletsSaga(): Generator<
	| CallEffect<AxiosResponse<ResponseData<WalletResponse[]>>>
	| PutEffect<AnyAction>,
	void,
	AxiosResponse<ResponseData<WalletResponse[]>>
> {
	try {
		yield put(setLoading(true));
		const res = yield call(walletRequest.getWallets);
		yield put(setWallets(res.data.data));
	} catch (error) {
		console.log(error);
	} finally {
		yield put(setLoading(false));
	}
}
export function* walletSaga() {
	yield takeLatest(getWalletReq, getWalletsSaga);
}
