import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SectionTitle from '../UI/SectionTitle';
import moment from 'moment';
import blogService from '../../service/blog.request';
import { Skeleton } from 'antd';
import { config } from '../../config';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { getStaleTime } from '../../utils/common';

interface DataBlog {
	tag: string;
	publishedAt: string;
	title: string;
	description: string;
	url: string;
	image: string;
}

const LatestNews = () => {
	const queryClient = useQueryClient();
	const [blogs, setBlogs] = useState<DataBlog[]>([]);
	const [loading, setLoading] = useState(false);

	const getBlogs = async () => {
		try {
			setLoading(true);
			const response = await queryClient.fetchQuery({
				queryKey: [QUERY_KEYS.GET_BLOGS],
				queryFn: () => blogService.getBlogs(),
				staleTime: getStaleTime(15),
			});
			const fixTimeFormat = (dateString: string) => {
				return dateString.replace(/T(\d):/, 'T0$1:');
			};
			setBlogs(
				response.data.data.map((blog: any) => ({
					title: blog.title,
					description: blog.content,
					image: blog.enclosure,
					url: blog.link,
					publishedAt: moment(fixTimeFormat(blog.pubDate)).format(
						'MMM D, YYYY'
					),
				}))
			);
		} catch (error) {
			console.error('Error fetching blogs', error);
			return [];
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getBlogs();
	}, []);

	const handleClickBlog = (url: string) => {
		const tg = (window as any)?.Telegram.WebApp;
		if (tg) {
			tg.openLink(url);
		} else {
			window.open(url, '_blank');
		}
	};

	return (
		<Wrapper>
			<Header>
				<SectionTitle text="Latest News" />
				<ViewAllLink href={config.BLOG_DOMAIN} target="_blank">
					View All
				</ViewAllLink>
			</Header>

			<Container>
				{loading ? (
					<ListCart>
						{Array.from({ length: 3 }, (_, i) => i).map((i) => (
							<CardLoad key={i}>
								<Skeleton.Input style={{ height: '92px' }} />
								<Skeleton paragraph={{ rows: 2 }} />
							</CardLoad>
						))}
					</ListCart>
				) : (
					<ListCart>
						{blogs.map((blog, index) => (
							<CardWrapper
								key={index}
								isPaddingRight={blogs.length - 1 === index}
							>
								<Card onClick={() => handleClickBlog(blog.url)}>
									<CardImage src={blog.image} alt="new" />
									<CardContent>
										<CardTime>{blog.publishedAt}</CardTime>
										<CardTitle>{blog.title}</CardTitle>
									</CardContent>
								</Card>
							</CardWrapper>
						))}
					</ListCart>
				)}
			</Container>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	max-width: 100%;
	overflow-x: hidden;
`;
const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;
const ViewAllLink = styled.a`
	color: #d5d5d5;
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	padding: 0 16px;
	text-decoration: none;
`;

const ListCart = styled.div`
	display: flex;
	width: 100%;
	gap: 12px;
	padding: 0 16px;
	padding-bottom: 12px;
`;

const Container = styled.div`
	overflow-x: auto;
	max-width: 100%;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
`;

const CardWrapper = styled.div<{ isPaddingRight: boolean }>`
	padding-right: ${(props) => (props.isPaddingRight ? '16px' : 0)};
	width: 100%;
`;

const Card = styled.div`
	min-width: 160px;
	width: 160px;
	height: 100%;
	overflow: hidden;
	border-radius: 10px;
	background: linear-gradient(90deg, #082954 0%, #143b6e 100%);
	box-shadow: 0px 5.317px 13.293px 0px rgba(0, 0, 0, 0.05);
	cursor: pointer;
	margin-top: 1px;
	transition: box-shadow 0.3s ease;
	&:hover {
		box-shadow: #00abeb 0px 1px 1px 0px, #00abeb 0px 0px 0px 1px;
	}
`;

const CardLoad = styled(Card)`
	height: 182.5px;
`;
const CardImage = styled.img`
	width: 100%;
	height: 92px;
	object-fit: cover;
`;
const CardContent = styled.div`
	display: flex;
	padding: 10px 8px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
`;
const CardTime = styled.div`
	color: #b4b4b4;
	text-align: center;

	/* Caption 3 */
	font-family: Poppins;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 12px; /* 120% */
`;
const CardTitle = styled.div`
	color: #fff;
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 15px;
	//max 3 rows
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;

export default LatestNews;
