import { configureStore } from '@reduxjs/toolkit';
import counter from './reducers/counter.slice';
import loyaltyWallet from './reducers/loyalty-wallet.slice';
import todo from './reducers/todo.reducer';
import booster from './reducers/booster.slice';
import user from './reducers/user.slice';
import referral from './reducers/referral.slice';
import farm from './reducers/farm.slice';
import wallet from './reducers/wallet.slice';
import mission from './reducers/mission.slice';
import createSagaMiddleware from 'redux-saga';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
	reducer: {
		counter,
		todo,
		loyaltyWallet,
		booster,
		user,
		referral,
		farm,
		wallet,
		mission,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
