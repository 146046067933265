export const ACCESS_TOKEN = 'access_token';
export const SOUND_CONFIG = 'SOUND_CONFIG';
export const SUPPORTED_PLATFORM = ['android', 'android_x', 'ios'];

export enum NotificationType {
	TELEGRAM_AGE = 'TELEGRAM_AGE',
	INVITE_FRIEND = 'INVITE_FRIEND',
	JOIN_WITH_CODE = 'JOIN_WITH_CODE',
	REFERRAL_COMMISSION = 'REFERRAL_COMMISSION',
	BOOSTER_BONUS = 'BOOSTER_BONUS',
}

export enum BalanceType {
	MISSON = 'MISSION',
	REFERRAL_REWARD = 'REFERRAL_REWARD',
	FARM = 'FARM',
	BUY_PACKAGE = 'BUY_PACKAGE',
	REFERRAL_COMMISSION = 'REFERRAL_COMMISSION',
	TELEGRAM_AGE = 'TELEGRAM_AGE',
	BOOSTER_BONUS = 'BOOSTER_BONUS',
	LUCKY_SPIN = 'LUCKY_SPIN',
	TELEGRAM_ACCOUNT = 'TELEGRAM_ACCOUNT',
}

export enum LoyaltyRewardType {
	TELEGRAM_AGE = 'TELEGRAM_AGE',
	TELEGRAM_ACCOUNT = 'TELEGRAM_ACCOUNT',
	INVITER = 'INVITER',
}

export enum ECampaignStatus {
	LIVE = 'LIVE',
	FINISHED = 'FINISHED',
	ALL = 'ALL',
	YOUR_CAMPAIGN = 'YOUR_CAMPAIGN',
}

export enum TransactionStatus {
	PENDING = 'PENDING',
	PROCESSING = 'PROCESSING',
	TRANSFERRED = 'TRANSFERRED',
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED',
	WITHDRAW_CHECKING = 'WITHDRAW_CHECKING',
}

export enum CampaignRewardStatus {
	PENDING = 'PENDING',
	PROCESSING = 'PROCESSING',
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED',
}

export const DEFAULT_AVATAR = '/images/default_avatar.png?v=1';

export enum SearchTag {
	LUCKY_SPIN = 'lucky_spin',
	MISSION = 'mission',
	CAMPAIGN = 'campaign',
	WITHDRAW = 'withdraw',
	LOTTERY_PRIZE = 'lottery_prize',
	LOTTERY_TICKET = 'lottery_ticket',
}

export const QUERY_KEYS = {
	CURRENCY_REWARD_CONFIG: 'currencyRewardConfig',
	LATEST_SPINS: 'latestSpins',
	GET_CAMPAIGNS: 'getCampaigns',
	GET_BLOGS: 'getBlogs',
	GET_LOYALTY_REWARDS: 'getLoyaltyRewards',
	GET_MY_EVENTS: 'getMyEvents',
	GET_BALANCE: 'getBalance',
	GET_TOTAL_SPIN: 'getTotalSpin',
	GET_SUMMARY_REFERRAL: 'getSummaryReferral',
	GET_OVERALL_RANKING: 'getOverallRanking',
	GET_MY_RANKING: 'getMyRanking',
	GET_MY_REWARDS: 'getMyRewards',
	GET_RANKING: 'getRanking',
	GET_CAMPAIGN_REWARDS: 'getCampaignRewards',
	COUNT_ACTIVE_NETWORK: 'countActiveNetwork',
	GET_PACKAGES: 'getPackages',
	GET_TRANSACTIONS: 'getTransactions',
	GET_ME: 'getMe',
	GET_CURRENCY_WITHDRAW_SETTING: 'getCurrencyWithdrawSetting',
	GET_CURRENCY_DEPOSIT_SETTING: 'getCurrencyDepositSetting',
	GET_DECENTRALIZE_ADDRESSES: 'getDecentralizeAddresses',
	GET_PAYMENT_WALLET: 'getPaymentWallet',
	GET_MY_RANKING_OVERALL: 'GET_MY_RANKING_OVERALL',
	GET_LIST_NETWORK: 'getListNetwork',
	GET_POOLS: 'getPools',
	GET_LOTTERY_POOLS: 'getLotteryPools',
	GET_POOL_DETAIL: 'getPoolDetail',
	GET_MY_TICKETS: 'getMyTickets',
	GET_RESULTS: 'getResults',
};

export const INVITE_TEXT = `
🌟 Explore the PadTON Mini App 🌟

The PadTON mini app offers a fun investment and entertainment space, making it super easy for users to discover exciting opportunities on the TON blockchain. 🚀🎮:

⏳ Non-stop farming to automatically stack up those sweet, sweet tokens—just sit back and watch! 💰🧘‍♂️
🏅 Complete tasks and climb the leaderboard for epic rewards—feel like a champion! 🏆💪
🎁 Unlock even more rewards and airdrops in special events—because who doesn’t love free stuff? 🎉🎁

Join now so you don’t miss out on all the action!🚀💨`;

export const VERSION = '2.1.7';

export const IS_PASSED_REQUIREMENT = 'IS_PASSED_REQUIREMENT';

export enum CAMPAIGN_TYPE {
	MISSION = 'MISSION',
}

export enum Network {
	TON = 'TON',
	BSC = 'BSC',
}
