import styled from 'styled-components';
import { fadeIn } from '../../constants/css';
import SectionTitle from '../UI/SectionTitle';

export const Wrapper = styled.div`
	animation: ${fadeIn} 0.5s ease;
`;

export const Container = styled.div`
	padding: 16px;
`;

export const StyledTitlePage = styled.div`
	font-size: 18px;
	line-height: 28px;
	font-weight: 600;
	text-align: center;
	color: #fff;
	display: flex;
	align-items: center;
	gap: 4px;
	justify-content: center;
`;

export const GifIcon = styled.img`
	width: 28px;
	height: 28px;
`;

export const TabsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	width: 100%;
	gap: 10px;
	margin-bottom: 20px;
`;
export const Desc = styled.div`
	color: #fff;
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 26px;
	margin-top: 2px;
	padding: 0 16px;
	margin-bottom: 20px;
`;

export const LeftBlock = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const UserAvatar = styled.img`
	width: 38px;
	height: 38px;
	border-radius: 999px;
	overflow: hidden;
`;

export const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
`;
export const UserName = styled.div`
	color: #fff;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
`;

export const FriendNumber = styled(UserName)`
	font-size: 12px;
	font-weight: 400;
`;

export const RightBlock = styled.div`
	color: #fff;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	width: 38px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const RankingList = styled.div`
	margin-top: 12px;
	border-radius: 10px;
	background: linear-gradient(90deg, #082954 0%, #143b6e 100%);
`;

export const User = styled.div`
	position: relative;
	display: flex;
	padding: 12px 16px;
	align-items: center;
	justify-content: space-between;
	&:last-child {
		border-bottom: none;
	}
`;

export const PopupContent = styled.div`
	max-height: 60vh;
	overflow-y: auto;
	overflow-x: hidden;
`;

export const UserRanking = styled.div`
	border-radius: 8px;
	border: 1.4px dashed #7fc2eb;
	background: linear-gradient(
			319deg,
			#fff -1.9%,
			rgba(255, 255, 255, 0) 52.62%,
			#fff 105%
		),
		#02143c;
	background-blend-mode: soft-light, normal;
	box-shadow: 0px 2px 2px 0px #02143c, 0px 0px 16px 0px rgba(2, 20, 60, 0.32);

	display: flex;
	padding: 12px 16px;
	align-items: center;
	justify-content: space-between;
	margin-top: 12px;
`;
export const OverallRanking = styled.div`
	animation: ${fadeIn} 0.5s ease;
`;

export const Divider = styled.div`
	height: 1px;
	width: 80%;
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.2) 50%,
		rgba(255, 255, 255, 0) 100%
	);
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
`;

export const CampaignsWrapper = styled.div`
	color: #fff;
	font-family: Poppins;
	animation: ${fadeIn} 0.5s ease;
`;

export const CampaignTopBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const StyledSelectionTitle = styled(SectionTitle)`
	padding: 0;
	margin-bottom: 0;
`;

export const FilterBlock = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;

	color: #d5d5d5;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 21px;
	svg {
		transform: rotate(90deg);
	}
`;

export const CampaignList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 12px;
`;
export const Loader = styled.div``;
export const LoadingWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
`;

export const Card = styled.div`
	display: flex;
	padding: 12px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	box-sizing: border-box;
	width: 100%;
	min-height: 190px;

	border-radius: 10px;
	background: linear-gradient(90deg, #082954 0%, #143b6e 100%);
	.avatar-loading {
		width: 72px;
	}
	.ant-skeleton.ant-skeleton-element {
		width: 100% !important;
	}
`;

export const CardLoading = styled(Card)`
	height: 160px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const DotTag = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 999px;
	background: #fff;
`;

export const CardTopBlock = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
	width: 100%;
`;

export const CampaignImageWrapper = styled.div`
	position: relative;
	width: 72px;
	height: 72px;
	border-radius: 8px;
	overflow: hidden;
	min-width: 72px;
`;
export const CampaignImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
`;

export const CampaignStatus = styled.div<{ isFinished?: boolean }>`
	position: absolute;
	bottom: 5px;
	right: 0;
	left: 0;
	margin: auto;
	width: fit-content;
	padding: 4px 10px;
	border-radius: 50px;
	background: ${(props) => (props.isFinished ? '#EB0000' : '#15B643')};
	color: #fff;
	font-family: Poppins;
	font-size: 9px;
	font-weight: 700;
	line-height: 12px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	gap: 3px;
`;

export const CampaignInfo = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
`;

export const CampaignName = styled.div`
	color: #fff;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 600;
	line-height: 26px;
	text-transform: uppercase;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const CampaignDesc = styled.div`
	display: flex;
	align-items: center;
	gap: 2px;
	color: #b4b4b4;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
`;

export const IconWrapper = styled.div`
	svg {
		path {
			fill: #b4b4b4;
		}
	}
	width: 20px;
	height: 20px;
`;

export const TopLeaderBlock = styled.div`
	border-radius: 10px;
	background: #07264e;
	box-shadow: 0px 5.317px 13.293px 0px rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: column;
	gap: 8px;
	flex: 1;
	width: 100%;
	padding: 12px;
	box-sizing: border-box;
`;

export const TopLeaderHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const TopLeaderTitle = styled.div`
	color: #fff;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 500;
	line-height: 21px;
`;

export const YourRanking = styled.div`
	color: #fff;
	text-align: right;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
`;
export const YourRankingNumber = styled.span`
	font-weight: 600;
`;

export const TopUsers = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 9px;
`;

export const TopUserBlock = styled.div`
	border-radius: 4px;
	background: linear-gradient(
		90deg,
		rgba(0, 122, 255, 0.3) 0%,
		rgba(0, 73, 153, 0.3) 100%
	);
	display: flex;
	padding: 4px 8px;
	align-items: center;
	gap: 4px;

	color: #fff;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 600;
	line-height: 21px;
`;

export const MedalIcon = styled.img`
	width: 20px;
	height: 20px;
`;
