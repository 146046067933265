import styled from 'styled-components';

const Loading = () => {
	return (
		<LoadingWrapper>
			<img
				src="/images/loading.svg"
				alt="loading"
				width={'50px'}
				height={'50px'}
			/>
		</LoadingWrapper>
	);
};

export default Loading;

export const LoadingWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 164px);
`;
