const SilverMedalIc = () => {
	return (
		<svg
			width={38}
			height={38}
			viewBox="0 0 38 38"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M25.2278 24.5957V34.8346C25.2278 35.679 24.2778 36.1013 23.6444 35.5735L20.0556 32.6179C20.0556 32.6179 19.95 32.6179 19.95 32.5124C19.6333 32.3013 19.3167 32.1957 19 32.1957C18.5778 32.1957 18.2611 32.3013 17.9444 32.6179L14.3556 35.679C13.7222 36.2068 12.7722 35.7846 12.7722 34.9401V24.7013H25.2278V24.5957Z"
				fill="#F45170"
			/>
			<path
				d="M25.2278 24.5957V34.8346C25.2278 35.679 24.2778 36.1013 23.6444 35.5735L20.0556 32.6179C20.0556 32.6179 19.95 32.6179 19.95 32.5124C19.6333 32.3013 19.3167 32.1957 19 32.1957V24.5957H25.2278Z"
				fill="#E93565"
			/>
			<path
				d="M25.2278 27.0234V28.6068C24.2778 28.8179 23.1167 28.5012 22.2722 28.7123C21.2167 29.029 20.1611 30.1901 19 30.1901C17.8389 30.1901 16.7833 29.029 15.7278 28.7123C14.7778 28.5012 13.6167 28.8179 12.7722 28.6068V27.0234H25.2278Z"
				fill="#CC104A"
			/>
			<path
				d="M31.1388 19.0003C30.8221 20.1614 31.3499 21.5336 30.8221 22.5892C30.1888 23.6447 28.711 23.8559 27.8666 24.7003C27.0221 25.5447 26.811 27.0225 25.7555 27.6559C24.8055 28.1836 23.3277 27.6559 22.1666 27.9725C21.111 28.2892 20.0555 29.4503 18.8943 29.4503C17.7332 29.4503 16.6777 28.2892 15.6221 27.9725C14.461 27.6559 13.0888 28.1836 12.0332 27.6559C11.0832 27.1281 10.7666 25.5447 9.92212 24.7003C9.07767 23.8559 7.5999 23.6447 6.96656 22.5892C6.43879 21.6392 6.96656 20.1614 6.6499 19.0003C6.33323 17.9447 5.17212 16.8892 5.17212 15.7281C5.17212 14.567 6.33323 13.5114 6.6499 12.4559C6.96656 11.2947 6.43879 9.92253 6.96656 8.86697C7.49434 7.91697 9.07767 7.6003 9.92212 6.75586C10.7666 5.91142 10.9777 4.43364 12.0332 3.8003C12.9832 3.27253 14.461 3.8003 15.6221 3.48364C16.6777 3.16697 17.7332 2.00586 18.8943 2.00586C20.0555 2.00586 21.111 3.16697 22.1666 3.48364C23.3277 3.8003 24.6999 3.27253 25.7555 3.8003C26.7055 4.32808 27.0221 5.91142 27.8666 6.75586C28.711 7.6003 30.1888 7.81141 30.8221 8.86697C31.3499 9.81697 30.8221 11.2947 31.1388 12.4559C31.4555 13.5114 32.6166 14.567 32.6166 15.7281C32.6166 16.8892 31.4555 17.9447 31.1388 19.0003Z"
				fill="#D8D8D8"
			/>
			<g opacity="0.4">
				<path
					d="M29.2388 15.7272C29.2388 21.3216 24.6999 25.9661 18.9999 25.9661C13.4054 25.9661 8.76099 21.4272 8.76099 15.7272C8.76099 10.1327 13.2999 5.48828 18.9999 5.48828C24.5943 5.48828 29.2388 10.1327 29.2388 15.7272Z"
					fill="white"
				/>
			</g>
			<path
				d="M28.1831 15.7283C28.1831 20.7949 24.0664 24.9116 18.9997 24.9116C13.9331 24.9116 9.81641 20.7949 9.81641 15.7283C9.81641 10.6616 13.9331 6.54492 18.9997 6.54492C24.0664 6.54492 28.1831 10.6616 28.1831 15.7283Z"
				fill="#C8C8C8"
			/>
			<path
				d="M28.1832 16.3615C27.8665 11.5059 23.8554 7.70593 18.9999 7.70593C14.1443 7.70593 10.1332 11.5059 9.81653 16.3615C9.49986 10.8726 13.9332 6.43926 18.9999 6.54482C24.1721 6.65037 28.4999 10.767 28.1832 16.3615Z"
				fill="#797979"
			/>
			<path
				d="M14.3554 8.9735C14.0388 8.9735 13.8277 9.18461 13.6166 9.39572C13.7221 9.50128 14.7777 10.3457 14.6721 10.4513C14.5666 10.5568 13.511 9.81795 13.4054 9.71239C13.2999 9.9235 13.1943 10.2402 13.2999 10.4513C13.6166 13.0902 13.1943 13.1957 12.6666 10.5568C12.561 10.2402 12.4554 10.0291 12.2443 9.81795C12.1388 9.9235 11.2943 10.9791 11.1888 10.8735C11.0832 10.7679 11.8221 9.71239 11.9277 9.60683C11.7166 9.50128 11.3999 9.50128 11.1888 9.50128C8.44433 9.71239 8.33878 9.39572 11.0832 8.86795C11.3999 8.76239 11.611 8.65683 11.8221 8.44572C11.8221 8.44572 11.8221 8.44572 11.7166 8.34017C10.2388 7.17906 10.4499 7.0735 11.9277 8.0235H12.0332C12.1388 7.81239 12.2443 7.49572 12.1388 7.28461C11.8221 4.54017 12.2443 4.54017 12.7721 7.17906C12.7721 7.49572 12.9832 7.70683 13.1943 7.91795C13.1943 7.91795 13.1943 7.91795 13.2999 7.81239C14.461 6.33461 14.5666 6.54572 13.6166 8.0235V8.12906C13.7221 8.34017 14.0388 8.44572 14.2499 8.44572C16.9943 8.12906 16.9943 8.44572 14.3554 8.9735Z"
				fill="white"
			/>
			<g opacity="0.5">
				<path
					d="M14.3555 31.773C14.3555 33.2508 14.2499 33.3564 14.0388 33.3564C13.9333 33.3564 13.7222 33.2508 13.7222 31.773C13.7222 30.2953 13.8277 29.873 14.0388 29.873C14.2499 29.873 14.3555 30.2953 14.3555 31.773Z"
					fill="white"
				/>
			</g>
			<g filter="url(#filter0_d_7201_5691)">
				<path
					d="M15.9373 18.6194C16.7052 18.0177 17.3346 17.4992 17.8254 17.0638C18.3163 16.6283 18.7279 16.181 19.0604 15.7219C19.3929 15.2548 19.5592 14.8115 19.5592 14.3919C19.5592 14.1385 19.4998 13.9406 19.3811 13.7981C19.2702 13.6556 19.104 13.5844 18.8823 13.5844C18.6527 13.5844 18.4746 13.6833 18.3479 13.8812C18.2213 14.0713 18.1619 14.3523 18.1698 14.7244H15.9136C15.9373 14.0198 16.0917 13.4379 16.3767 12.9788C16.6617 12.5117 17.0338 12.1713 17.4929 11.9575C17.9521 11.7358 18.4627 11.625 19.0248 11.625C19.9986 11.625 20.7229 11.8665 21.1979 12.3494C21.6729 12.8323 21.9104 13.4577 21.9104 14.2256C21.9104 15.049 21.6334 15.8208 21.0792 16.5413C20.5329 17.2617 19.8482 17.9069 19.0248 18.4769H21.9936V20.365H15.9373V18.6194Z"
					fill="white"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_7201_5691"
					x="15.9136"
					y="11.625"
					width="6.58008"
					height="9.24023"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="0.5" dy="0.5" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.476389 0 0 0 0 0.476389 0 0 0 0 0.476389 0 0 0 1 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_7201_5691"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_7201_5691"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	);
};
export default SilverMedalIc;
