import React from 'react';

export default function PlusIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={19}
			height={18}
			viewBox="0 0 19 18"
			fill="none"
		>
			<path
				d="M5 9H9.5M9.5 9H14M9.5 9V13.5M9.5 9V4.5"
				stroke="white"
				strokeOpacity="0.9"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
