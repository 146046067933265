import React from 'react';

export default function RankingActiveIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={21}
			height={20}
			viewBox="0 0 21 20"
			fill="none"
		>
			<path
				d="M16.05 17.7004V18.0004C16.05 18.5004 15.55 19.0004 14.85 19.0004H6.54998C5.94998 19.0004 5.34998 18.6004 5.34998 18.0004V17.6004C5.34998 16.4004 6.44998 15.5004 7.84998 15.5004H8.04998V14.4004C8.84998 14.7004 9.74998 14.8004 10.55 14.8004C11.45 14.8004 12.25 14.6004 13.05 14.4004V15.5004H13.25C14.95 15.5004 16.05 16.5004 16.05 17.7004Z"
				fill="white"
			/>
			<path
				d="M19.75 4.5C19.75 3.6 19.05 2.9 18.15 2.9H16.95V2.6C16.95 1.7 16.25 1 15.35 1H6.15C5.25 1 4.55 1.7 4.55 2.6V2.8H3.35C2.45 2.8 1.75 3.6 1.75 4.5V6C1.75 8.2 3.05 10.1 5.05 10.9L5.95 11.3C7.05 12.7 8.85 13.5 10.75 13.5C12.65 13.5 14.35 12.6 15.55 11.3L16.45 10.9C18.45 10.1 19.75 8.2 19.75 6V4.5ZM4.85 9.2C3.75 8.5 3.15 7.3 3.15 5.9V4.5C3.15 4.4 3.25 4.3 3.35 4.3H4.55V7.3C4.55 7.9 4.65 8.6 4.85 9.2ZM13.25 6.9L12.35 7.7L12.55 8.9C12.65 9.4 12.15 9.7 11.75 9.5L10.75 8.9L9.65 9.5C9.25 9.7 8.85 9.3 8.85 8.9L9.05 7.7L8.25 6.9C7.85 6.5 8.05 6 8.55 5.9L9.75 5.7L10.25 4.6C10.45 4.2 11.05 4.2 11.25 4.6L11.75 5.7L12.95 5.9C13.45 6 13.65 6.5 13.25 6.9ZM18.35 5.9C18.35 7.2 17.65 8.4 16.65 9.1C16.85 8.5 16.95 7.8 16.95 7.2V4.2H18.15C18.25 4.2 18.35 4.3 18.35 4.4V5.9Z"
				fill="white"
			/>
		</svg>
	);
}
