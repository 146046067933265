import React from 'react';

const GroupUserIc = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M5.33339 8.25013C6.29989 8.25013 7.08339 7.46662 7.08339 6.50012C7.08339 5.53362 6.29989 4.75012 5.33339 4.75012C4.36689 4.75012 3.58339 5.53362 3.58339 6.50012C3.58339 7.46662 4.36689 8.25013 5.33339 8.25013Z"
				fill="white"
			/>
			<path
				d="M7.2525 9.45754C6.66538 9.82391 6.18115 10.3337 5.8454 10.9388C5.50965 11.544 5.33343 12.2247 5.33333 12.9167H4.16667C3.85753 12.9158 3.56132 12.7926 3.34273 12.574C3.12414 12.3554 3.00092 12.0592 3 11.75V10.5834C3.00138 10.1197 3.1862 9.67536 3.51409 9.34747C3.84198 9.01958 4.2863 8.83476 4.75 8.83337H5.91667C6.17133 8.83395 6.42281 8.8901 6.65353 8.9979C6.88425 9.10571 7.08867 9.26257 7.2525 9.45754Z"
				fill="white"
			/>
			<path
				d="M14.6666 8.25013C15.6331 8.25013 16.4166 7.46662 16.4166 6.50012C16.4166 5.53362 15.6331 4.75012 14.6666 4.75012C13.7001 4.75012 12.9166 5.53362 12.9166 6.50012C12.9166 7.46662 13.7001 8.25013 14.6666 8.25013Z"
				fill="white"
			/>
			<path
				d="M17 10.5834V11.75C16.9991 12.0592 16.8759 12.3554 16.6573 12.574C16.4387 12.7926 16.1425 12.9158 15.8333 12.9167H14.6667C14.6666 12.2247 14.4903 11.544 14.1546 10.9388C13.8188 10.3337 13.3346 9.82391 12.7475 9.45754C12.9113 9.26257 13.1157 9.10571 13.3465 8.9979C13.5772 8.8901 13.8287 8.83395 14.0833 8.83337H15.25C15.7137 8.83476 16.158 9.01958 16.4859 9.34747C16.8138 9.67536 16.9986 10.1197 17 10.5834Z"
				fill="white"
			/>
			<path
				d="M9.99998 9.22233C11.2886 9.22233 12.3333 8.17766 12.3333 6.889C12.3333 5.60033 11.2886 4.55566 9.99998 4.55566C8.71131 4.55566 7.66664 5.60033 7.66664 6.889C7.66664 8.17766 8.71131 9.22233 9.99998 9.22233Z"
				fill="white"
			/>
			<path
				d="M13.8889 13.0248V13.6297C13.8875 14.1106 13.7027 14.5714 13.3748 14.9114C13.0469 15.2515 12.6026 15.4431 12.1389 15.4446H8.6389C8.1752 15.4431 7.73088 15.2515 7.40299 14.9114C7.0751 14.5714 6.89029 14.1106 6.8889 13.6297V13.0248C6.8889 12.2226 7.19619 11.4533 7.74317 10.886C8.29015 10.3188 9.03202 10.0001 9.80557 10.0001H10.9722C11.7458 10.0001 12.4876 10.3188 13.0346 10.886C13.5816 11.4533 13.8889 12.2226 13.8889 13.0248Z"
				fill="white"
			/>
		</svg>
	);
};

export default GroupUserIc;
