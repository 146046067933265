import styled from 'styled-components';
import { Button } from 'antd';
import { formatCurrency } from '../../../utils/copy';
import PopupUI from '../Popup/PopupUI';

type modalType = 'token' | 'turn';
interface CongratulationModalProps {
	open: boolean;
	onClose: () => void;
	type: modalType;
	number?: number;
	tokenImage?: string;
	tokenSymbol?: string;
	description?: string;
	actionText?: string;
	cta?: () => void;
}
const CongratulationModal = ({
	open,
	onClose,
	type,
	number,
	tokenImage,
	tokenSymbol,
	description,
	actionText = 'Continue',
	cta,
}: CongratulationModalProps) => {
	return (
		<PopupUI open={open} onClose={onClose} title="Congratulation">
			<Wrapper>
				<FireworkImage src="/images/spin/firework.png" alt="firework" />
				<StyledImage
					src={type === 'token' ? tokenImage : '/images/spin/spinner.svg'}
					alt={type}
				/>
				<Content>
					{type === 'token' ? (
						<>
							<Detail>
								<TokenImage src={tokenImage} alt="token" />
								{formatCurrency(number || 0, 10)} {tokenSymbol}
							</Detail>
							<StyledDescription>
								{description ||
									`You have won ${formatCurrency(
										number || 0,
										10
									)} ${tokenSymbol} from
								Lucky Spin`}
							</StyledDescription>
						</>
					) : (
						<>
							<ReceiveDesc>You have received</ReceiveDesc>
							<TotalTurn>x{number} Spins</TotalTurn>
						</>
					)}
					<ContinueBtn
						onClick={() => {
							cta && cta();
							onClose();
						}}
					>
						{actionText}
					</ContinueBtn>
				</Content>
			</Wrapper>
		</PopupUI>
	);
};

export default CongratulationModal;

const FireworkImage = styled.img`
	height: 255px;
	width: 100%;
	object-fit: contain;
`;
const ContinueBtn = styled(Button)`
	width: 100%;
	margin-top: 16px;
	border-radius: 10px;
	background: #00abeb;
	height: 45px;

	color: #fff;
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 600;
	line-height: 21px;
`;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
const StyledImage = styled.img`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 133px;
	height: 133px;
	object-fit: contain;
`;

const Content = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 8px;
	width: 100%;
`;

const Detail = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	color: #f5d245;
	font-size: 22px;
	font-weight: 600;
	line-height: 34px;
`;

const TokenImage = styled.img`
	width: 32px;
	height: 32px;
	object-fit: contain;
`;

const StyledDescription = styled.div`
	color: #fff;
	text-align: center;
	font-size: 14px;
	line-height: 18px;
`;

const ReceiveDesc = styled(StyledDescription)`
	font-size: 18px;
`;

const TotalTurn = styled(Detail)`
	font-size: 26px;
`;
