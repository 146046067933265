import { useEffect, useRef, useState } from 'react';
import ArrowSquareLeftIc from '../Icons/ArrowSquareLeftIc';
import {
	Cards,
	FilterBlock,
	Header,
	Loader,
	LoadingWrapper,
	StyledIDOCard,
	StyledSectionTitle,
	Wrapper,
} from './IDOList.styled';
import FilterPopup from './FilterPopup';
import { QuerySearchStatus } from '../../constants/launchpad';
import fairlaunchRequest from '../../service/fairlaunch.request';
import { normalizeDataForProjectCard } from '../../utils/launchpad';
import Loading from '../UI/Loading';
import Notfound from '../UI/NotFound';
import { QUERY_KEYS } from '../../constants';
import { useQueryClient } from '@tanstack/react-query';
import { getStaleTime } from '../../utils/common';
const PAGE_SIZE = 10;
const DEFAULT_PARAMS = {
	page: 1,
	pageSize: PAGE_SIZE,
	searchStatus: QuerySearchStatus.ALL,
	order: 'updatedAt desc',
};
const IDOList = () => {
	const queryClient = useQueryClient();
	const [open, setOpen] = useState(false);
	const [params, setParams] = useState<PoolContentParams>({
		...DEFAULT_PARAMS,
	});
	const [total, setTotal] = useState<number>(0);
	const [launchpads, setLaunchpads] = useState<IDOItem[]>([]);
	const [loading, setLoading] = useState(true);
	const [totalPage, setTotalPage] = useState(1);
	const loader = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && !loading) {
					setParams((params) => ({
						...params,
						page: params.page ? params.page + 1 : 1,
					}));
				}
			},
			{ threshold: 1 }
		);
		if (loader.current) {
			observer.observe(loader.current);
		}
		return () => {
			if (loader.current) {
				observer.unobserve(loader.current);
			}
		};
	}, [loading]);

	useEffect(() => {
		getPools(params);
	}, [JSON.stringify(params)]);

	const getPools = async (params: PoolContentParams) => {
		try {
			if (params.page && params.page > totalPage) return;
			setLoading(true);
			const res = await queryClient.fetchQuery({
				queryKey: [QUERY_KEYS.GET_POOLS, params],
				queryFn: () => fairlaunchRequest.getPoolContent(params),
				staleTime: getStaleTime(15),
			});
			const formatedData = res.data.data.map((item: PoolContent) =>
				normalizeDataForProjectCard(item)
			);
			const newLaunchpads =
				params.page === 1 ? formatedData : [...launchpads, ...formatedData];
			setLaunchpads(newLaunchpads);
			setTotal(res.data.meta.totalRecord);
			setTotalPage(res.data.meta.totalPage || 1);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};
	return (
		<>
			<Wrapper>
				<Header>
					<StyledSectionTitle text={`All Launchpads (${total})`} />
					<FilterBlock onClick={() => setOpen(true)}>
						Filter By
						<ArrowSquareLeftIc />
					</FilterBlock>
				</Header>
				{loading && params.page === 1 ? (
					<Loading />
				) : (
					<>
						{launchpads.length === 0 ? (
							<Notfound content={<div>No project found.</div>} />
						) : (
							<>
								<Cards>
									{launchpads.map((launchpad, index) => (
										<StyledIDOCard {...launchpad} key={index} />
									))}
									{loading && params.page && params.page > 1 && (
										<LoadingWrapper style={{ height: '50px' }}>
											<img
												src="/images/loading.svg"
												alt="loading"
												width={'30px'}
												height={'30px'}
											/>
										</LoadingWrapper>
									)}
									<Loader ref={loader} />
								</Cards>
							</>
						)}
					</>
				)}
			</Wrapper>
			<FilterPopup
				params={params}
				setParams={setParams}
				open={open}
				setOpen={setOpen}
			/>
		</>
	);
};

export default IDOList;
