import { Typography } from 'antd';
import styled from 'styled-components';
import { formatCurrency } from '../../utils/copy';
import { RootState, useAppSelector } from '../../redux/store';
import { ROUTES } from '../../routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { DEFAULT_AVATAR } from '../../constants';
import SmallArrowRightIc from '../Icons/SmallArrowRightIc';
import { getName } from '../../utils/common';

interface IProps {
	balance?: string;
	isDecoration?: boolean;
}

export default function Header({ balance, isDecoration = true }: IProps) {
	const navigate = useNavigate();
	const location = useLocation();
	const user = useAppSelector((state: RootState) => state.user);
	const fullName = getName(user.firstName, user.lastName, user.username);
	const renderLeftBlock = (pathname: string) => {
		switch (pathname) {
			case ROUTES.FARM:
				return (
					<ProfileWrapper onClick={() => navigate(ROUTES.PACKAGES)}>
						<Icon src={'/images/icons/rocket-ic.png'} alt="rocket" />
						<Username>Booster</Username>
					</ProfileWrapper>
				);

			default:
				return (
					<ProfileWrapper>
						<AvatarWrapper src={user.avatar || DEFAULT_AVATAR} />
						<Username>{fullName.trim() || user.username}</Username>
					</ProfileWrapper>
				);
		}
	};
	return (
		<Container>
			<Wrapper>
				{renderLeftBlock(location.pathname)}
				{!!balance && (
					<BalanceWrapper
						id="user-balance"
						onClick={() => navigate(ROUTES.WALLET)}
					>
						<Balance>{formatCurrency(parseFloat(balance), 6)}</Balance>
						<CurrencyImg src={'/images/tokens/patc.svg'} alt="patc" />
						<SmallArrowRightIc />
					</BalanceWrapper>
				)}
			</Wrapper>

			{isDecoration && (
				<DecorativeLight src={'/images/layout/light.png'} alt="light-decor" />
			)}
		</Container>
	);
}

const Icon = styled.img`
	width: 20px;
	height: 20px;
	object-fit: contain;
`;
const Container = styled.div`
	width: 100%;
`;

const Wrapper = styled.div`
	padding: 8px 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	z-index: 2;
	backdrop-filter: blur(10px);
	height: 60px;
	box-sizing: border-box;
	position: fixed;
	top: 0;
	z-index: 1030;
	width: 100%;
`;

const ProfileWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	justify-content: center;
	border-radius: 100px;
	background: rgba(255, 255, 255, 0.07);
	padding: 10px 12px;
	height: 40px;
	box-sizing: border-box;
`;

const AvatarWrapper = styled.img`
	width: 30px;
	height: 30px;
	border-radius: 100%;
`;

const Username = styled(Typography)`
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	max-width: 100px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const BalanceWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
	padding: 6px 12px;
	border-radius: 100px;
	border: 1px solid rgba(255, 255, 255, 0.2);
	height: 40px;
	box-sizing: border-box;
	transition: all 0.2s;
`;

const Balance = styled(Typography)`
	color: #fff;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 600;
	line-height: 21px; /* 150% */
`;

const CurrencyImg = styled.img`
	width: 24px;
	height: 24px;
	object-fit: contain;
`;

const DecorativeLight = styled.img`
	width: 100%;
	position: absolute;
	z-index: 1;
	top: -4px;
`;
